import React from 'react';
import { ClipLoader } from '@hqo/react-components-library';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme.colors.greys.adminGreyDark};
`;

export const BlobsLoader: React.FC = (): JSX.Element => (
  <LoaderWrapper>
    <ClipLoader size={16} color="currentColor" />
  </LoaderWrapper>
);
