import { VerticalDoc } from 'store/vertical-docs/types';
import { useMemo, useCallback, useEffect } from 'react';
import { useVerticalDocs } from 'store/vertical-docs/hooks';
import { useRouteMatch } from 'react-router-dom';
import qs from 'qs';
import { push, replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'shared/hooks/use-search-params.hook';

export interface VerticalDocSelectItem {
  label: string;
  value: string;
  vertical: string;
  version: string;
  docUrl: string;
}

export type VerticalDocQueryParams = Pick<VerticalDocSelectItem, 'vertical' | 'version'>;

export const useSelectItems = (): VerticalDocSelectItem[] => {
  const [docs] = useVerticalDocs();

  return useMemo(
    () =>
      docs
        .map((verticalDoc: VerticalDoc) =>
          verticalDoc.versions.map((verticalDocVersion) => ({
            label: `${verticalDoc.display_name} (${verticalDocVersion.version})`,
            value: `${verticalDoc.name}:${verticalDocVersion.version}`,
            vertical: verticalDoc.name,
            version: verticalDocVersion.version,
            docUrl: verticalDocVersion.doc_url,
          })),
        )
        .flat(),
    [docs],
  );
};

export const useSelectedVerticalDoc = (): VerticalDocSelectItem | null => {
  const selectItems = useSelectItems();
  const query = useSearchParams<VerticalDocQueryParams>();

  return selectItems.find(({ vertical, version }) => vertical === query.vertical && version === query.version) ?? null;
};

export const useDocSelectHandler = (useReplace?: boolean): ((docItem: VerticalDocSelectItem | null) => void) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  return useCallback(
    (docItem: VerticalDocSelectItem | null): void => {
      const querySearch = qs.stringify(
        {
          vertical: docItem?.vertical,
          version: docItem?.version,
        },
        { skipNulls: true },
      );
      const targetUrl = querySearch ? `${path}?${querySearch}` : path;

      if (useReplace) {
        dispatch(replace(targetUrl));
      } else {
        dispatch(push(targetUrl));
      }
    },
    [dispatch, path, useReplace],
  );
};

export const useSelectChangeHandler = (): React.ChangeEventHandler<{ value: unknown }> => {
  const selectItems = useSelectItems();
  const selectDoc = useDocSelectHandler();

  return useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      const itemToSelect = selectItems.find(({ value }) => value === event.target.value) ?? null;

      selectDoc(itemToSelect);
    },
    [selectDoc, selectItems],
  );
};

export const useDefaultDoc = (): void => {
  const [firstDoc] = useSelectItems();
  const selectDoc = useDocSelectHandler(true);
  const query = useSearchParams<VerticalDocQueryParams>();

  useEffect(() => {
    if ((!query.vertical || !query.version) && firstDoc) {
      selectDoc(firstDoc);
    }
  }, [query, firstDoc, selectDoc]);
};
