import { VerticalAdapters } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { getVerticalAdaptersStateSelector } from './selectors';
import { getVerticalAdapters } from './actions';
import { useEffect } from 'react';
import { EntityStateArray } from 'shared/types';

export const useVerticalAdapters = (verticalUuid: string): EntityStateArray<VerticalAdapters> => {
  const verticalAdapterState = useSelector(getVerticalAdaptersStateSelector(verticalUuid));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!verticalAdapterState) {
      dispatch(getVerticalAdapters.request(verticalUuid));
    }
  }, [verticalAdapterState]);

  if (!verticalAdapterState) {
    return [[], true, false];
  }

  return [verticalAdapterState.data, !!verticalAdapterState.loading, !!verticalAdapterState.error];
};
