import { useAppConfigBlobDefinition } from 'store/app-config-blob-definitions/hooks';
import { useFormikContext } from 'formik';
import { AppConfigFormValues } from 'components/app-config/app-config-form-values.interface';

export const useBlobDefinition = (): ReturnType<typeof useAppConfigBlobDefinition> => {
  const {
    values: { vertical_adapter_uuid },
  } = useFormikContext<AppConfigFormValues>();

  return useAppConfigBlobDefinition(vertical_adapter_uuid);
};
