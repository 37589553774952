import React from 'react';
import { HeaderAndContentLayout, TwoColumnLayout } from '@hqo/react-components-library';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { AppInstanceConfigForm } from 'components/app-instance-config/app-instance-config-form';
import { ConfigHeader } from 'components/config-header';
import { AppInstanceConfigFormContent } from 'components/app-instance-config/app-instance-config-form-content';
import { useCreateAppInstanceConfigHandler, useCreateButtonLoading } from './create-app-instance-config-page.hooks';
import { useCurrentAppInstanceConfigBlobDefinition } from 'store/app-instance-config-blob-definitions/hooks';

const HeaderAndContentLayoutStyled = styled(HeaderAndContentLayout)`
  padding-top: 20px;
`;

const TwoColumnLayoutStyled = styled(TwoColumnLayout)`
  grid-gap: 120px;
  padding: 0 64px;
`;

export const CreateAppInstanceConfigPage = (): JSX.Element => {
  const intl = useIntl();
  const handleSubmit = useCreateAppInstanceConfigHandler();
  const loading = useCreateButtonLoading();
  const [, blobLoading] = useCurrentAppInstanceConfigBlobDefinition();

  return (
    <AppInstanceConfigForm onSubmit={handleSubmit}>
      <HeaderAndContentLayoutStyled>
        <ConfigHeader
          backLink="/instances"
          backLinkLabel={intl.formatMessage({ id: 'labels.instances' })}
          buttonText={intl.formatMessage({ id: 'buttons.configure' })}
          loading={loading}
          title={intl.formatMessage({ id: 'labels.configureInstance' })}
          isSaveDisabled={blobLoading}
        />
        <TwoColumnLayoutStyled>
          <AppInstanceConfigFormContent />
        </TwoColumnLayoutStyled>
      </HeaderAndContentLayoutStyled>
    </AppInstanceConfigForm>
  );
};
