import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { Building } from './types';

export const searchBuildings: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.searchBuildings.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const query = action.payload;

      return apiClient(state)
        .getBuildings(query)
        .pipe(
          map(({ response }: AjaxResponse) =>
            actions.searchBuildings.success({
              query,
              buildings: response as Building[],
            }),
          ),
          catchError((error: Error) => of(actions.searchBuildings.failure({ query, error }))),
        );
    }),
  );

export const getBuilding: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getBuilding.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const uuid = action.payload;

      return apiClient(state)
        .getBuilding(uuid)
        .pipe(
          map(({ response }: AjaxResponse) => actions.getBuilding.success(response as Building)),
          catchError((error: Error) => of(actions.getBuilding.failure(error))),
        );
    }),
  );
