import { createAsyncAction, createAction } from 'typesafe-actions';
import { AppInstanceConfigBlobDefinition } from './types';
import { GetEntityResponseDto, GetEntityErrorDto } from 'shared/types';

type GetAppInstanceConfigBlobDefinitionResponseDto = GetEntityResponseDto<AppInstanceConfigBlobDefinition>;

export const getAppInstanceConfigBlobDefinition = createAsyncAction(
  'app_instance_config_blobs/GET_DEFINITION_REQUEST',
  'app_instance_config_blobs/GET_DEFINITION_RESPONSE',
  'app_instance_config_blobs/GET_DEFINITION_FAILURE',
)<string, GetAppInstanceConfigBlobDefinitionResponseDto, GetEntityErrorDto>();

export const selectAppInstanceConfigBlobDefinition = createAction('app_instance_config_blobs/SELECT')<string | null>();
