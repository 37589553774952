import React, { useCallback, useEffect } from 'react';
import { Notification as NotificationInterface } from 'store/notifications/types';
import { useDispatch } from 'react-redux';
import { hideNotification } from 'store/notifications/actions';
import { useIntl } from 'react-intl';
import { Toast } from '@hqo/react-components-library';

export type NotificationProps = NotificationInterface;
export const Notification: React.FC<NotificationProps> = ({
  uuid,
  text,
  translationKey,
  dataCy,
  autoHideMs = 8000,
  type = 'success',
}: NotificationProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(hideNotification(uuid));
  }, [dispatch, uuid]);
  const { formatMessage } = useIntl();
  const message = text || (translationKey && formatMessage({ id: translationKey }));

  useEffect(() => {
    if (!message) {
      handleClose();
    }
  }, [message, handleClose]);

  if (!message) {
    return null;
  }

  return <Toast text={message} variant={type} autoHideMs={autoHideMs} onClose={handleClose} dataCy={dataCy} />;
};
