import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { AppState, AppSearchDto } from './types';
import { EntityState } from 'shared/types';

export const appsState = (state: RootState): RootState['apps'] => state.apps;

type AppStateSelector = (state: RootState) => AppState | null;
export const getAppStateSelector = (appUuid: string): AppStateSelector =>
  createSelector(appsState, (state: RootState['apps']): AppState | null => state.data[appUuid] ?? null);

type AppSearchStateSelector = (state: RootState) => EntityState<AppSearchDto[]> | null;
export const getAppsSearchStateSelector = (developerUuid: string, query: string): AppSearchStateSelector =>
  createSelector(
    appsState,
    (state: RootState['apps']): EntityState<AppSearchDto[]> | null => state.searchCache[developerUuid]?.[query] ?? null,
  );

type SearchedAppSelector = (state: RootState) => AppSearchDto | null;
export const getSearchedAppSelector = (appUuid: string): SearchedAppSelector =>
  createSelector(appsState, (state: RootState['apps']): AppSearchDto | null => state.searchedApps[appUuid] ?? null);
