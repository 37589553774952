import { createAsyncAction, createAction } from 'typesafe-actions';
import { Instance, ModifyAppInstanceConfigPayload, CreateApInstanceConfigPayload } from './types';

interface GetAppInstanceErrorDto {
  error: Error;
  uuid: string;
}

interface PatchAppConfigDto {
  uuid: string;
  appInstanceConfig: ModifyAppInstanceConfigPayload;
}

export const getInstance = createAsyncAction(
  'instances/GET_INSTANCE_REQUEST',
  'instances/GET_INSTANCE_RESPONSE',
  'instances/GET_INSTANCE_FAILURE',
)<string, Instance, GetAppInstanceErrorDto>();

export const getInstances = createAsyncAction(
  'instances/GET_INSTANCES_REQUEST',
  'instances/GET_INSTANCES_RESPONSE',
  'instances/GET_INSTANCES_FAILURE',
)<void, Instance[], Error>();

export const patchInstance = createAsyncAction(
  'instances/PATCH_APP_INSTANCE_REQUEST',
  'instances/PATCH_APP_INSTANCE_RESPONSE',
  'instances/PATCH_APP_INSTANCE_FAILURE',
)<PatchAppConfigDto, Instance, GetAppInstanceErrorDto>();

export const createAppInstanceConfig = createAsyncAction(
  'instances/CREATE_REQUEST',
  'instances/CREATE_RESPONSE',
  'instances/CREATE_FAILURE',
)<CreateApInstanceConfigPayload, Instance, Error>();

export const showConfirmDeletionDialog = createAction('instances/SHOW_CONFIRM_DELETION_DIALOG')<string>();

export const deleteAppInstanceConfig = createAsyncAction(
  'instances/DELETE_INSTANCE_REQUEST',
  'instances/DELETE_INSTANCE_RESPONSE',
  'instances/DELETE_INSTANCE_FAILURE',
)<void, string, Error>();

export const cancelDeletionDialog = createAction('instances/CANCEL_DELETION_DIALOG')<void>();
