import styled from 'styled-components';

export const ApiIndicatorWrapper = styled.section`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.colors.greys.adminBlack}b3`};
  color: ${({ theme }) => theme.colors.universal.white};
`;

export const Bold = styled.b`
  margin-right: 4px;
`;
