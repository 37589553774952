import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { AppConfigBlobDefinition } from './types';

export const getAppConfigBlobDefinition: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAppConfigBlobDefinition.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { payload: uuid } = action;

      return apiClient(state)
        .getAppConfigBlobDefinition(uuid)
        .pipe(
          map(({ response }: AjaxResponse) =>
            actions.getAppConfigBlobDefinition.success({
              uuid,
              data: response as AppConfigBlobDefinition,
            }),
          ),
          catchError((error: Error) => of(actions.getAppConfigBlobDefinition.failure({ uuid, error }))),
        );
    }),
  );
