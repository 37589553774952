import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.b`
  font-size: 12px;
`;

const Value = styled.span`
  font-size: 12px;
  text-align: right;
`;

interface InfoRowProps {
  label: string;
  value: string;
}

export const InfoRow: React.FC<InfoRowProps> = ({ label, value }: InfoRowProps): JSX.Element => (
  <Row>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Row>
);
