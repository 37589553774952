import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { VerticalAdaptersState } from './types';

export const verticalsAdaptersState = (state: RootState): RootState['verticalAdapters'] => state.verticalAdapters;

type VerticalAdaptersStateSelector = (state: RootState) => VerticalAdaptersState | null;

export const getVerticalAdaptersStateSelector = (verticalUuid: string): VerticalAdaptersStateSelector =>
  createSelector(
    verticalsAdaptersState,
    (state: RootState['verticalAdapters']): VerticalAdaptersState | null => state[verticalUuid] ?? null,
  );
