import { useState, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { AppSearchDto } from 'store/apps/types';

type StringChangeHandler = (value: string | null) => void;

export const useSelectState = (): [
  string | null,
  React.ChangeEventHandler<{ value: unknown }>,
  StringChangeHandler,
] => {
  const [value, setValue] = useState<string | null>(null);
  const handleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setValue((event.target.value as string) ?? null);
  }, []);

  return [value, handleChange, setValue];
};

export const useAppConfigChangeHandler = (): StringChangeHandler => {
  const { setFieldValue } = useFormikContext<AppInstanceConfigFormValues>();

  return useCallback(
    (value: string | null): void => {
      setFieldValue('app_config_uuid', value);
    },
    [setFieldValue],
  );
};

export const useAppChangeHandler = (
  selectedApp: AppSearchDto | null,
  handleAppConfigChange: StringChangeHandler,
): void => {
  useEffect(() => {
    const selectedAppConfigUuid = [...(selectedApp?.config_uuids ?? [])].pop() ?? null;
    handleAppConfigChange(selectedAppConfigUuid);
  }, [selectedApp]);
};

export const useDeveloperChangeHandler = (
  handleDeveloperChange: React.ChangeEventHandler<{ value: unknown }>,
  handleAppChange: StringChangeHandler,
  handleAppConfigChange: StringChangeHandler,
): React.ChangeEventHandler<{ value: unknown }> =>
  useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      handleDeveloperChange(event);
      handleAppChange(null);
      handleAppConfigChange(null);
    },
    [handleDeveloperChange, handleAppChange, handleAppConfigChange],
  );
