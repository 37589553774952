import { InstancesState } from './types';
import { createReducer } from 'typesafe-actions';
import * as instancesActions from './actions';
import { combineReducers } from 'redux';

export const initialState: InstancesState = {
  loading: false,
  error: null,
  data: {},
  createAppInstanceConfigState: {
    loading: false,
    error: null,
  },
  deleteAppInstanceConfigState: {
    uuid: null,
    loading: false,
  },
};

const loading = createReducer(initialState.loading)
  .handleAction(instancesActions.getInstances.request, () => true)
  .handleAction(instancesActions.getInstances.success, () => false)
  .handleAction(instancesActions.getInstances.failure, () => false);

const error = createReducer(initialState.error)
  .handleAction(instancesActions.getInstances.request, () => null)
  .handleAction(instancesActions.getInstances.success, () => null)
  .handleAction(instancesActions.getInstances.failure, (_state, action) => action.payload);

const data = createReducer(initialState.data)
  .handleAction(instancesActions.getInstance.request, (state, action) => ({
    ...state,
    [action.payload]: { loading: true, data: null, error: null },
  }))
  .handleAction(instancesActions.getInstance.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: action.payload, error: null },
  }))
  .handleAction(instancesActions.getInstance.failure, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: null, error: action.payload.error },
  }))
  .handleAction(instancesActions.getInstances.success, (state, action) =>
    action.payload.reduce(
      (hash, instance, index) => ({
        ...hash,
        [instance.uuid]: {
          loading: false,
          data: instance,
          error: null,
          order: index,
        },
      }),
      {},
    ),
  )
  .handleAction(instancesActions.patchInstance.request, (state, action) => ({
    ...state,
    [action.payload.uuid]: {
      ...state[action.payload.uuid],
      updating: true,
    },
  }))
  .handleAction(instancesActions.patchInstance.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: {
      ...state[action.payload.uuid],
      updating: false,
      data: action.payload,
    },
  }))
  .handleAction(instancesActions.patchInstance.failure, (state, action) => ({
    ...state,
    [action.payload.uuid]: {
      ...state[action.payload.uuid],
      updating: false,
      error: action.payload.error,
    },
  }))
  .handleAction(instancesActions.deleteAppInstanceConfig.success, (state, action) =>
    Object.entries(state).reduce((newState, [key, value]) => {
      if (key === action.payload) {
        return newState;
      }

      return {
        ...newState,
        [key]: value,
      };
    }, {}),
  );

const createAppInstanceConfigState = createReducer(initialState.createAppInstanceConfigState)
  .handleAction(instancesActions.createAppInstanceConfig.request, () => ({
    loading: true,
    error: null,
  }))
  .handleAction(instancesActions.createAppInstanceConfig.success, () => ({
    loading: false,
    error: null,
  }))
  .handleAction(instancesActions.createAppInstanceConfig.failure, (state, action) => ({
    loading: false,
    error: action.payload,
  }));

const deleteAppInstanceConfigState = createReducer(initialState.deleteAppInstanceConfigState)
  .handleAction(instancesActions.showConfirmDeletionDialog, (state, action) => ({
    uuid: action.payload,
    loading: false,
  }))
  .handleAction(instancesActions.cancelDeletionDialog, () => ({
    uuid: null,
    loading: false,
  }))
  .handleAction(instancesActions.deleteAppInstanceConfig.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(instancesActions.deleteAppInstanceConfig.success, () => ({
    uuid: null,
    loading: false,
  }))
  .handleAction(instancesActions.deleteAppInstanceConfig.failure, () => ({
    uuid: null,
    loading: false,
  }));

export default combineReducers({
  data,
  loading,
  error,
  createAppInstanceConfigState,
  deleteAppInstanceConfigState,
});
