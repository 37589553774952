import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { VerticalAdapters } from './types';

export const getVerticalAdapters: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getVerticalAdapters.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { payload: verticalUuid } = action;

      return apiClient(state)
        .getVerticalAdapters(verticalUuid)
        .pipe(
          map(({ response }: AjaxResponse) =>
            actions.getVerticalAdapters.success({
              verticalUuid,
              adapters: response as VerticalAdapters,
            }),
          ),
          catchError((error: Error) => of(actions.getVerticalAdapters.failure({ verticalUuid, error }))),
        );
    }),
  );
