import { ajax, AjaxResponse } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import qs from 'qs';
import { CreateAppConfigPayload, PatchAppConfigPayload } from 'store/app-configs/types';
import { CreateApInstanceConfigPayload, ModifyAppInstanceConfigPayload } from 'store/instances/types';
import { excludedVerticals } from '../../../shared';

export class ApiClient {
  constructor(private baseUrl: string, private authToken?: string | null) {}

  private get DefaultHeaders(): Record<string, unknown> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
    };
  }

  getCurrentUser(): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getApp(uuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/apps/${uuid}`, this.DefaultHeaders);
  }

  getApps(): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/apps`, this.DefaultHeaders);
  }

  getInstance(instanceUuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/app-instance-configs/${instanceUuid}`, this.DefaultHeaders);
  }

  getInstances(): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/app-instance-configs?${excludedVerticals}`, this.DefaultHeaders);
  }

  getVerticalAdapters(verticalUuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/verticals/${verticalUuid}/adapters`, this.DefaultHeaders);
  }

  getAppConfig(appUuid: string, appConfigUuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/apps/${appUuid}/configs/${appConfigUuid}`, this.DefaultHeaders);
  }

  createAppConfig(appUuid: string, appConfig: CreateAppConfigPayload): Observable<AjaxResponse> {
    return ajax.post(`${this.baseUrl}/api/apps/${appUuid}/configs`, appConfig, this.DefaultHeaders);
  }

  patchAppConfig(appUuid: string, appConfigUuid: string, appConfig: PatchAppConfigPayload): Observable<AjaxResponse> {
    return ajax.patch(`${this.baseUrl}/api/apps/${appUuid}/configs/${appConfigUuid}`, appConfig, this.DefaultHeaders);
  }

  getBuildings(name: string, limit = 25): Observable<AjaxResponse> {
    const query = qs.stringify({ name, limit }, { skipNulls: true });

    return ajax.get(`${this.baseUrl}/api/buildings/names?${query}`, this.DefaultHeaders);
  }

  getBuilding(uuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/buildings/names/${uuid}`, this.DefaultHeaders);
  }

  getDevelopers(name: string, limit = 25): Observable<AjaxResponse> {
    const query = qs.stringify({ name, limit }, { skipNulls: true });

    return ajax.get(`${this.baseUrl}/api/companies/developers?${query}`, this.DefaultHeaders);
  }

  searchApps(developerUuid: string, name: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/companies/developers/${developerUuid}/apps?name=${name}`, this.DefaultHeaders);
  }

  createAppInstanceConfig(appInstanceConfig: CreateApInstanceConfigPayload): Observable<AjaxResponse> {
    return ajax.post(`${this.baseUrl}/api/app-instance-configs`, appInstanceConfig, this.DefaultHeaders);
  }

  modifyAppInstanceConfig(uuid: string, appInstanceConfig: ModifyAppInstanceConfigPayload): Observable<AjaxResponse> {
    return ajax.patch(`${this.baseUrl}/api/app-instance-configs/${uuid}`, appInstanceConfig, this.DefaultHeaders);
  }

  deleteAppInstanceConfig(uuid: string): Observable<AjaxResponse> {
    return ajax.delete(`${this.baseUrl}/api/app-instance-configs/${uuid}`, this.DefaultHeaders);
  }

  getAppConfigBlobDefinition(adapterUuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/app-configs/meta/adapters/${adapterUuid}`, this.DefaultHeaders);
  }

  getAppInstanceConfigBlobDefinition(appConfigUuid: string): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/app-instance-configs/meta/app-configs/${appConfigUuid}`, this.DefaultHeaders);
  }

  getVerticalDocs(): Observable<AjaxResponse> {
    return ajax.get(`${this.baseUrl}/api/verticals/docs`);
  }
}
