import React from 'react';
import { Select, SelectItem } from '@hqo/react-components-library';
import styled from 'styled-components';
import { useSelectItems, useSelectedVerticalDoc, useSelectChangeHandler } from 'components/docs-select/hooks';
import { useIntl } from 'react-intl';

const StyledSelect = styled(Select)`
  margin: 32px 0 0 64px;
  max-width: 250px;
`;

export const DocsSelect = (): JSX.Element => {
  const selectItems = useSelectItems();
  const selectedItem = useSelectedVerticalDoc();
  const handleChange = useSelectChangeHandler();
  const { formatMessage } = useIntl();

  return (
    <StyledSelect
      placeholder={formatMessage({ id: 'labels.selectVertical' })}
      value={selectedItem?.value ?? ''}
      selectedValueLabel={selectedItem?.label ?? ''}
      onChange={handleChange}
    >
      {selectItems.map(
        (item): JSX.Element => (
          <SelectItem key={item.value} value={item.value} renderedValue={item.label} />
        ),
      )}
    </StyledSelect>
  );
};
