import React from 'react';

import { Router } from 'components/router';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { Loading } from 'components/loading';

import { DeleteAppInstanceConfigDialog } from 'components/delete-app-instance-config-dialog';
import { usePendo } from 'hooks/use-pendo.hook';

export const App: React.FC = (): JSX.Element => {
  const shouldWaitForLogin = useExternalLogin();
  usePendo();

  if (shouldWaitForLogin) {
    return <Loading />;
  }

  return (
    <DeleteAppInstanceConfigDialog>
      <Router />
    </DeleteAppInstanceConfigDialog>
  );
};
