import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppInstanceConfigFormValues } from 'components/app-instance-config';
import { getAppInstanceSelector, getAppInstanceUpdatingSelector } from 'store/instances/selectors';
import { patchInstance } from 'store/instances/actions';

export const useButtonLoading = (configUuid: string): boolean =>
  useSelector(getAppInstanceUpdatingSelector(configUuid));

type SaveAppConfigHandler = (appInstance: AppInstanceConfigFormValues) => void;
export const useSaveAppInstanceHandler = (uuid: string): SaveAppConfigHandler => {
  const dispatch = useDispatch();
  return useCallback(
    (appInstanceConfig: AppInstanceConfigFormValues): void => {
      dispatch(patchInstance.request({ uuid, appInstanceConfig }));
    },
    [uuid, dispatch],
  );
};

export const useAppInstanceInitialValues = (configUuid: string): Partial<AppInstanceConfigFormValues> => {
  const appInstance = useSelector(getAppInstanceSelector(configUuid));

  if (!appInstance) {
    return {};
  }

  return {
    app_config_uuid: appInstance.app_config_uuid,
    base_url: appInstance.base_url,
    enabled: appInstance.enabled,
    config: appInstance.config,
    owner_type: appInstance.owner_type,
    owner_uuid: appInstance.owner_uuid,
    external_id: appInstance.external_id,
  };
};
