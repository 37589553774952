import { PendoArgs } from '../shared/interfaces/pendo.interface';
import { useEffect } from 'react';
import { useCurrentUser } from 'hooks/use-current-user.hook';

export const usePendo = (): void => {
  const { pendo } = window;

  const [user] = useCurrentUser();

  const initPendo = (args: PendoArgs): void => {
    pendo.initialize(args);
  };

  useEffect(() => {
    initPendo({
      visitor: {
        id: user?.uuid,
        first_name: user?.first_name,
        last_name: user?.last_name,
      },
      account: {
        id: user?.uuid,
      },
    });
  });
};
