import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConfigFormValues } from 'components/app-config';
import { createAppConfig } from 'store/app-configs/actions';
import { createAppConfigsLoading } from 'store/app-configs/selectors';

export const useCreateButtonLoading = (): boolean => useSelector(createAppConfigsLoading);

type CreateAppConfigHandler = (appConfig: AppConfigFormValues) => void;
export const useCreateAppConfigHandler = (appUuid: string): CreateAppConfigHandler => {
  const dispatch = useDispatch();

  return useCallback(
    (appConfig: AppConfigFormValues): void => {
      dispatch(createAppConfig.request({ appUuid, appConfig }));
    },
    [appUuid, dispatch],
  );
};
