import * as appActions from 'store/apps/actions';
import * as appConfigActions from 'store/app-configs/actions';
import * as userActions from 'store/user/actions';
import * as instancesActions from 'store/instances/actions';
import * as verticalAdaptersActions from 'store/vertical-adapters/actions';
import * as buildingsActions from 'store/buildings/actions';
import * as developersActions from 'store/developers/actions';
import * as appConfigBlobDefinitionsActions from 'store/app-config-blob-definitions/actions';
import * as appInstanceConfigBlobDefinitionsActions from 'store/app-instance-config-blob-definitions/actions';
import * as notificationActions from 'store/notifications/actions';
import * as verticalDocsActions from 'store/vertical-docs/actions';
import { routerActions, CallHistoryMethodAction } from 'connected-react-router';
import { ActionType } from 'typesafe-actions';
import { Path, LocationState } from 'history';

// TODO: remove RouterActions custom types after https://github.com/supasate/connected-react-router/issues/286 fixed
type Push = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;
type Replace = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;

interface RouterActions {
  push: Push;
  replace: Replace;
  // go: Go; etc.
}

const actions = {
  router: routerActions as RouterActions,
  user: userActions,
  apps: appActions,
  instances: instancesActions,
  appConfigs: appConfigActions,
  verticalAdapters: verticalAdaptersActions,
  buildings: buildingsActions,
  developers: developersActions,
  appConfigBlobDefinitions: appConfigBlobDefinitionsActions,
  appInstanceConfigBlobDefinitions: appInstanceConfigBlobDefinitionsActions,
  notifications: notificationActions,
  verticalDocs: verticalDocsActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
