import React from 'react';
import { HeaderAndContentLayout, TwoColumnLayout } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import { AppConfigForm, AppConfigFormContent } from 'components/app-config';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useButtonLoading, useSaveAppConfigHandler, useAppConfigInitialValues } from './edit-app-config-page.hooks';
import { EditAppConfigRouteParams } from 'components/router/apps-route/app-route/config-route/config-route.interfaces';
import { AppSummary } from 'components/app-summary';
import { AppValidateCheckbox } from 'components/app-validate-checkbox';
import { ConfigHeader } from 'components/config-header';
import { StickySummarySection } from 'components/sticky-summary-section';
import { useCurrentAppConfigBlobDefinition } from 'store/app-config-blob-definitions/hooks';

const HeaderAndContentLayoutStyled = styled(HeaderAndContentLayout)`
  padding-top: 20px;
`;

const TwoColumnLayoutStyled = styled(TwoColumnLayout)`
  grid-gap: 120px;
  padding: 0 64px;
`;

export const EditAppConfigPage = (): JSX.Element => {
  const intl = useIntl();
  const {
    params: { appUuid, configUuid },
  } = useRouteMatch<EditAppConfigRouteParams>();
  const loading = useButtonLoading(configUuid);
  const handleSubmit = useSaveAppConfigHandler(appUuid, configUuid);
  const initialValues = useAppConfigInitialValues(configUuid);
  const [, blobLoading] = useCurrentAppConfigBlobDefinition();

  return (
    <AppConfigForm onSubmit={handleSubmit} initialValues={initialValues}>
      <HeaderAndContentLayoutStyled>
        <ConfigHeader
          backLink="/apps"
          backLinkLabel={intl.formatMessage({ id: 'labels.applications' })}
          buttonText={intl.formatMessage({ id: 'buttons.save' })}
          title={intl.formatMessage({ id: 'labels.configureApplication' })}
          loading={loading}
          isSaveDisabled={blobLoading}
        />
        <TwoColumnLayoutStyled>
          <AppConfigFormContent appUuid={appUuid} isEditMode />
          <StickySummarySection>
            <AppSummary appUuid={appUuid} />
            <AppValidateCheckbox title={intl.formatMessage({ id: 'appHasBeenValidated.checkbox' })} />
          </StickySummarySection>
        </TwoColumnLayoutStyled>
      </HeaderAndContentLayoutStyled>
    </AppConfigForm>
  );
};
