import React from 'react';
import styled from 'styled-components';
import { HeaderAndContentLayout, PageHeader, HeaderTitle } from '@hqo/react-components-library';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist';
import { Link } from 'react-router-dom';
import { TableWrapper } from '../../components/table-wrapper';
import { ConfigureApplicationTableHeader } from '../../components/configure-application-table-header';
import { InstancesTable } from '../../components/instances-table';
import { useInstances } from '../../store/instances/hooks';
import { getZIndex } from 'shared/z-index';

const PageHeaderStyled = styled(PageHeader)`
  z-index: ${getZIndex('header')};
  padding: 89px 68px 47px 64px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ButtonStyled = styled(Button)`
  width: 160px;
  height: 40px;
  font-weight: 400;
`;

export const InstancesPage: React.FC = (): JSX.Element => {
  const intl = useIntl();
  const [instances, loading] = useInstances();

  return (
    <HeaderAndContentLayout>
      <PageHeaderStyled>
        <HeaderTitle>
          <FormattedMessage id="labels.instances" />
        </HeaderTitle>
        <StyledLink to="instances/new">
          <ButtonStyled
            text={intl.formatMessage({ id: 'buttons.create' })}
            size="lg"
            variant="primaryHeader"
            dataCy="create-instance-button"
          />
        </StyledLink>
      </PageHeaderStyled>
      {!loading && (
        <TableWrapper>
          <ConfigureApplicationTableHeader />
          <InstancesTable instances={instances} />
        </TableWrapper>
      )}
    </HeaderAndContentLayout>
  );
};
