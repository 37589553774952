import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AppRoute } from './app-route';
import { ApplicationsPage } from 'pages/applications-page';
import { AppsRedirect } from 'components/router/apps-route/apps-redirect';

export const AppsRoute = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={ApplicationsPage} exact />
      <Route path={`${path}/:appUuid`} component={AppRoute} />
      <Route component={AppsRedirect} />
    </Switch>
  );
};
