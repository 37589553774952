import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ConfigState } from './types';
import * as userActions from '../user/actions';
import { getDefaultApiUrl } from 'store/config/get-default-url.util';

const FARM_URL_LS_KEY = 'farmUrl';

export const initialState: ConfigState = {
  apiUrl: localStorage.getItem(FARM_URL_LS_KEY) ?? getDefaultApiUrl(),
};

const apiUrl = createReducer(initialState.apiUrl)
  .handleAction(userActions.externalLogin.request, (state, action) => {
    const { farmUrl } = action.payload;

    if (farmUrl) {
      localStorage.setItem(FARM_URL_LS_KEY, farmUrl);
    } else {
      localStorage.removeItem(FARM_URL_LS_KEY);
    }

    return farmUrl ?? getDefaultApiUrl();
  })
  .handleAction(userActions.externalLogin.failure, () => {
    localStorage.removeItem(FARM_URL_LS_KEY);

    return getDefaultApiUrl();
  });

export default combineReducers({ apiUrl });
