import React from 'react';
import { AppInstanceInformationSection } from './app-instance-information-section';
import styled from 'styled-components';
import { AppInstanceConfigurationSection } from './app-instance-configuration-section';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { useCurrentAppInstanceConfigBlobDefinition } from 'store/app-instance-config-blob-definitions/hooks';
import { selectAppInstanceConfigBlobDefinition } from 'store/app-instance-config-blob-definitions/actions';
import { useConfigDefaultValues } from 'hooks/use-config-default-values';
import { useBlobChangeHandler } from 'hooks/use-blob-change-handler';

const AppInformationSectionStyled = styled(AppInstanceInformationSection)`
  margin-bottom: 40px;
`;

const AppInstanceConfigurationSectionStyled = styled(AppInstanceConfigurationSection)`
  margin-bottom: 40px;
`;

interface AppInstanceConfigFormContentProps {
  appInstanceConfigUuid?: string;
}

export const AppInstanceConfigFormContent: React.FC<AppInstanceConfigFormContentProps> = ({
  appInstanceConfigUuid,
}: AppInstanceConfigFormContentProps): JSX.Element => {
  const [definition, loading] = useCurrentAppInstanceConfigBlobDefinition();
  useBlobChangeHandler<AppInstanceConfigFormValues>(
    'app_config_uuid',
    selectAppInstanceConfigBlobDefinition,
    !!appInstanceConfigUuid,
  );
  useConfigDefaultValues(definition, loading);

  return (
    <section>
      <AppInformationSectionStyled appInstanceConfigUuid={appInstanceConfigUuid} />
      <AppInstanceConfigurationSectionStyled isEditMode={!!appInstanceConfigUuid} />
    </section>
  );
};
