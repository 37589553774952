import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { EditAppInstancePage } from 'pages/edit-app-instance-page';
import { InstancesRedirect } from 'components/router/instances-route/instances-redirect';
import { EditAppInstanceRouteParams } from './edit-instance-route.interface';
import { useInstance } from 'store/instances/hooks';
import { Loading } from 'components/loading';

export const EditAppInstanceRoute = (): JSX.Element => {
  const {
    path,
    params: { uuid },
  } = useRouteMatch<EditAppInstanceRouteParams>();
  const [, instanceLoading, instanceError] = useInstance(uuid);

  if (instanceLoading) {
    return <Loading />;
  }

  if (instanceError) {
    return <InstancesRedirect />;
  }

  return (
    <Switch>
      <Route path={path} component={EditAppInstancePage} />
      <Route component={InstancesRedirect} />
    </Switch>
  );
};
