/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { LabelField } from 'components/label-field';
import { useFieldFormikInfo, useSelectedItem } from './form-select.hooks';
import { Select, SelectTitle, SelectItem } from '@hqo/react-components-library';
import { useFieldTranslations } from 'hooks/form-field';
import { SelectItem as SelectItemInterface } from 'components/form-select/select-item.interface';

type StringPropertyNames<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];

interface FormSelectProps<T extends object> {
  sectionTranslationKey: string;
  name: StringPropertyNames<T>;
  readonly?: boolean;
  items: SelectItemInterface[];
  dataCy?: string;
}

export const FormSelect = <T extends object>({
  sectionTranslationKey,
  name,
  readonly,
  items,
  dataCy,
}: FormSelectProps<T>): JSX.Element => {
  const fieldName = name.toString();
  const { label, placeholder } = useFieldTranslations(sectionTranslationKey, fieldName);
  const { value, handleChange } = useFieldFormikInfo(fieldName);
  const selectedItem = useSelectedItem(items, value);

  if (readonly) {
    return <LabelField label={label} value={selectedItem?.label ?? ''} dataCy={dataCy} />;
  }

  return (
    <Select
      placeholder={placeholder}
      value={selectedItem?.value ?? ''}
      selectedValueLabel={selectedItem?.label ?? ''}
      name={fieldName}
      onChange={handleChange}
      titleComponent={<SelectTitle title={label} />}
      dataCy={dataCy}
    >
      {items.map(
        (item: SelectItemInterface): JSX.Element => (
          <SelectItem
            key={item.value}
            value={item.value}
            renderedValue={item.label}
            data-cy={`${dataCy}-item-${item.label}`}
          />
        ),
      )}
    </Select>
  );
};
