import React from 'react';
import { AppConfigFormValues } from 'components/app-config/app-config-form-values.interface';
import { ConfigForm } from 'components/config-form';
import { useValidationSchema, useInitialValues } from './hooks';

interface AppConfigFormProps {
  children: React.ReactNode;
  initialValues?: Partial<AppConfigFormValues>;
  onSubmit: (values: AppConfigFormValues) => void;
}

export const AppConfigForm: React.FC<AppConfigFormProps> = ({
  children,
  initialValues,
  onSubmit: handleSubmit,
}: AppConfigFormProps): JSX.Element => {
  const formInitialValues = useInitialValues(initialValues);
  const validationSchema = useValidationSchema();

  return (
    <ConfigForm<AppConfigFormValues>
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </ConfigForm>
  );
};
