import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { PayloadActionCreator } from 'typesafe-actions';

export const useBlobChangeHandler = <T>(
  key: keyof T,
  changeEvent: PayloadActionCreator<string, string | null>,
  isEditMode?: boolean,
): void => {
  const { values, setValues } = useFormikContext<T>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeEvent(((values[key] as unknown) as string) ?? null));
    if (!isEditMode) {
      setValues({
        ...values,
        config: null,
      });
    }
  }, [values[key]]);
};
