import { InfoUrl, DownloadUrl } from './info-url';
import { BaseLayout } from './base-layout';
import { ServerInputContainer } from './server-input-container';

interface HqOSwaggerPlugin {
  components: Record<string, unknown>;
}

const HqOCustomizationsPlugin = (): HqOSwaggerPlugin => ({
  components: {
    BaseLayout,
    ServerInputContainer,
    DownloadUrl,
    InfoUrl,
  },
});

export const HqoSwaggerPlugins = [HqOCustomizationsPlugin];
