import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { Building } from './types';
import { EntityState } from 'shared/types';

export const buildingsState = (state: RootState): RootState['buildings'] => state.buildings;

type BuildingSearchStateSelector = (state: RootState) => EntityState<Building[]> | null;
export const getBuildingsSearchStateSelector = (query: string): BuildingSearchStateSelector =>
  createSelector(
    buildingsState,
    (state: RootState['buildings']): EntityState<Building[]> | null => state.searchCache[query] ?? null,
  );

type BuildingSelector = (state: RootState) => Building | null;
export const getBuildingsSelector = (uuid: string): BuildingSelector =>
  createSelector(buildingsState, (state: RootState['buildings']): Building | null => state.buildings[uuid] ?? null);
