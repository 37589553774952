import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectApiUrl } from 'store/config/selectors';
import { ApiIndicatorWrapper, Bold } from './api-indicator.styles';

interface ApiIndicatorProps {
  className?: string;
}

export const ApiIndicator: React.FC<ApiIndicatorProps> = ({ className }: ApiIndicatorProps): JSX.Element | null => {
  const apiUrl = useSelector(selectApiUrl);

  if (!apiUrl) {
    return null;
  }

  return (
    <ApiIndicatorWrapper className={className} data-testid="api-url-indicator">
      <Bold>
        <FormattedMessage id="labels.url" />
      </Bold>
      <span data-testid="api-url-label">{apiUrl}</span>
    </ApiIndicatorWrapper>
  );
};
