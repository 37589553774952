import * as Yup from 'yup';

import { ObjectShape } from 'yup/lib/object';
import { useValidationSchema as useConfigValidationSchema } from '@hqo/dynamic-form-ui';
import { useCurrentAppConfigBlobDefinition } from 'store/app-config-blob-definitions/hooks';
import { useMemo } from 'react';
import { AppConfigFormValues } from 'components/app-config/app-config-form-values.interface';
import merge from 'lodash.merge';

const newConfigInitialValues: AppConfigFormValues = {
  vertical_adapter_uuid: '',
  base_url: '',
  version: '',
  enabled: true,
  config: null,
  validated_at: null,
};

export const useValidationSchema = (): Yup.ObjectSchema<ObjectShape> => {
  const [definition] = useCurrentAppConfigBlobDefinition();
  const configSchema = useConfigValidationSchema(definition);

  return useMemo(
    (): Yup.ObjectSchema<ObjectShape> =>
      Yup.object().shape({
        vertical_adapter_uuid: Yup.string().required(),
        base_url: Yup.string().url(),
        version: Yup.string().required(),
        enabled: Yup.boolean(),
        config: configSchema ?? Yup.object().nullable(),
        validated_at: Yup.date().nullable(),
      }),
    [configSchema],
  );
};

export const useInitialValues = (initialValues?: Partial<AppConfigFormValues>): AppConfigFormValues =>
  useMemo((): AppConfigFormValues => merge({}, newConfigInitialValues, initialValues ?? {}), [initialValues]);
