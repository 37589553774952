import React from 'react';
import { App } from 'components/app';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store/router/history';
import { HqoThemeProvider } from '@hqo/react-components-library';
import { IntlProvider } from 'components/intl-provider';
import { Notifications } from 'components/notifications';
import { RootStyles, StyledApiIndicator } from './root.styles';

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <IntlProvider>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <HqoThemeProvider>
            <RootStyles />
            <App />
            <StyledApiIndicator />
            <Notifications />
          </HqoThemeProvider>
        </ConnectedRouter>
      </BrowserRouter>
    </IntlProvider>
  </ReduxProvider>
);
