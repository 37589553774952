import React from 'react';
import { ConfirmDialog, InputAction, DialogContent, DialogContentText } from '@hqo/react-components-library';
import styled, { css, keyframes, FlattenSimpleInterpolation } from 'styled-components';
import {
  useDeleteInstanceConfigState,
  useCancelDeleteInstanceConfigHandler,
  useDeleteInstanceConfigHandler,
} from 'store/instances/hooks';
import { useIntl, FormattedMessage } from 'react-intl';

const blur = keyframes`
  from {
    filter: blur(0);
  }

  to {
    filter: blur(4px);
  }
`;

const Wrapper = styled.div<{ blurred: boolean }>`
  ${({ blurred }): FlattenSimpleInterpolation | null =>
    blurred
      ? css`
          animation: ${blur} 0.25s ease-in 1;
          animation-fill-mode: forwards;
        `
      : null}
`;

interface DeleteAppInstanceConfigDialogProps {
  children: React.ReactNode;
}

export const DeleteAppInstanceConfigDialog = ({ children }: DeleteAppInstanceConfigDialogProps): JSX.Element => {
  const { uuid, loading } = useDeleteInstanceConfigState();
  const isOpened = !!uuid;
  const handleCancel = useCancelDeleteInstanceConfigHandler();
  const handleConfirm = useDeleteInstanceConfigHandler();
  const { formatMessage } = useIntl();

  return (
    <>
      <ConfirmDialog placeholder="placeholder" onClose={handleCancel} open={isOpened}>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="labels.archiveConfirmation" />
          </DialogContentText>
        </DialogContent>
        <InputAction
          onClose={handleCancel}
          onConfirm={handleConfirm}
          isDeleteAction
          inputLabel={formatMessage({ id: 'labels.archiveLabel' })}
          placeholder={formatMessage({ id: 'labels.archivePlaceholder' })}
          agreeLabel={formatMessage({ id: 'labels.archiveAgreeLabel' })}
          agreeVariant="danger"
          agreeButtonLoading={loading}
          disagreeLabel={formatMessage({ id: 'labels.deleteCancelLabel' })}
          textToEnter={formatMessage({ id: 'labels.archiveWord' })}
          dataCy="instance-delete"
        />
      </ConfirmDialog>
      <Wrapper blurred={isOpened}>{children}</Wrapper>
    </>
  );
};
