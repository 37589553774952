import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { VerticalDoc } from './types';

export const getVerticalDocs: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getVerticalDocs.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      apiClient(state)
        .getVerticalDocs()
        .pipe(
          map(({ response }: AjaxResponse) => actions.getVerticalDocs.success(response as VerticalDoc[])),
          catchError((error: Error) => of(actions.getVerticalDocs.failure(error))),
        ),
    ),
  );
