import styled from 'styled-components';

export const DescriptionWrapper = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  line-height: 22px;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;
