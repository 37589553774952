import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { externalLogin } from 'store/user/actions';
import qs, { ParsedQs } from 'qs';

const parseQueryValue = (value?: string | ParsedQs | string[] | ParsedQs[]): string | null =>
  typeof value === 'string' ? decodeURIComponent(value) : null;

export const useExternalLogin = (): boolean => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { authToken: authTokenEncoded, farmUrl: farmUrlEncoded } = qs.parse(search?.replace(/^\?/, ''));
  const shouldWaitForLogin = !!authTokenEncoded || !!farmUrlEncoded;

  useEffect((): void => {
    if (shouldWaitForLogin) {
      const authToken = parseQueryValue(authTokenEncoded);
      const farmUrl = parseQueryValue(farmUrlEncoded);

      dispatch(externalLogin.request({ authToken, farmUrl }));
    }
  }, [dispatch, authTokenEncoded, farmUrlEncoded, externalLogin, shouldWaitForLogin]);

  return shouldWaitForLogin;
};
