import React from 'react';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { ConfigForm } from 'components/config-form';
import { useInitialValues, useValidationSchema } from './hooks';

interface AppInstanceConfigFormProps {
  children: React.ReactNode;
  initialValues?: Partial<AppInstanceConfigFormValues>;
  onSubmit: (values: AppInstanceConfigFormValues) => void;
}

export const AppInstanceConfigForm: React.FC<AppInstanceConfigFormProps> = ({
  children,
  initialValues,
  onSubmit: handleSubmit,
}: AppInstanceConfigFormProps): JSX.Element => {
  const formInitialValues = useInitialValues(initialValues);
  const validationSchema = useValidationSchema();

  return (
    <ConfigForm<AppInstanceConfigFormValues>
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </ConfigForm>
  );
};
