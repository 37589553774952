import React from 'react';
import { Developer } from 'store/developers/types';
import { useDevelopersSearch } from 'store/developers/hooks';
import { DynamicSelect } from 'components/dynamic-select';
import { useFieldTranslations } from 'hooks/form-field';

interface DeveloperSelectProps {
  selectedDeveloper: Developer | null;
  name: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  disabled?: boolean;
}

export const DeveloperSelect = ({
  selectedDeveloper,
  name,
  onChange: handleChange,
  disabled,
}: DeveloperSelectProps): JSX.Element => {
  const { label, placeholder } = useFieldTranslations('appInstanceInformationSection', 'developer');
  const [developers, loading, query, handleSearch] = useDevelopersSearch();

  return (
    <DynamicSelect
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      selectedItem={selectedDeveloper}
      name={name}
      dataCy="developer"
      items={developers}
      loading={loading}
      query={query}
      handleSearch={handleSearch}
      onChange={handleChange}
    />
  );
};
