import { combineReducers } from 'redux';

import routerReducer from 'store/router/reducer';
import userReducer from 'store/user/reducer';
import configReducer from 'store/config/reducer';
import appsReducer from 'store/apps/reducer';
import appConfigsReducer from 'store/app-configs/reducer';
import instancesReducer from 'store/instances/reducer';
import verticalAdaptersReducer from 'store/vertical-adapters/reducer';
import buildingsReducer from 'store/buildings/reducer';
import developersReducer from 'store/developers/reducer';
import appConfigBlobDefinitionsReducer from 'store/app-config-blob-definitions/reducer';
import appInstanceConfigBlobDefinitionsReducer from 'store/app-instance-config-blob-definitions/reducer';
import notificationsReducer from 'store/notifications/reducer';
import verticalDocsReducer from 'store/vertical-docs/reducer';
import { StateType } from 'typesafe-actions';

const rootReducer = combineReducers({
  config: configReducer,
  router: routerReducer,
  user: userReducer,
  apps: appsReducer,
  appConfigs: appConfigsReducer,
  instances: instancesReducer,
  verticalAdapters: verticalAdaptersReducer,
  buildings: buildingsReducer,
  developers: developersReducer,
  appConfigBlobDefinitions: appConfigBlobDefinitionsReducer,
  appInstanceConfigBlobDefinitions: appInstanceConfigBlobDefinitionsReducer,
  notifications: notificationsReducer,
  verticalDocs: verticalDocsReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
