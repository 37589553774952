import React from 'react';
import { CheckboxWrapper, CheckboxField } from '@hqo/react-components-library';

import styled from 'styled-components';
import { useAppValidatedCheckbox } from 'components/app-validate-checkbox/app-validate-checkbox.hook';

const StyledCheckboxWrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 16px 0px 4px 16px;
`;

interface AppValidateCheckboxProps {
  title: string;
}

export const AppValidateCheckbox: React.FC<AppValidateCheckboxProps> = ({
  title,
}: AppValidateCheckboxProps): JSX.Element => {
  const [checked, handleChange] = useAppValidatedCheckbox();

  return (
    <StyledCheckboxWrapper>
      <CheckboxWrapper title={title}>
        <CheckboxField checked={checked} onChange={handleChange} data-cy="validate-app-checkbox" />
      </CheckboxWrapper>
    </StyledCheckboxWrapper>
  );
};
