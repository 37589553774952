import React from 'react';
import { Paper } from '@hqo/react-components-library';
import styled from 'styled-components';
import { useApp } from 'store/apps/hooks';
import { EntityStateArray } from 'shared/types';
import { App } from 'store/apps/types';
import { CompanyLogo } from './company-logo';
import { AppInfo } from 'components/app-summary/app-info';
import { FormattedMessage } from 'react-intl';

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  padding: 24px;
`;

const StyledCompanyLogo = styled(CompanyLogo)`
  margin-top: 6px;
  margin-bottom: 30px;
`;

const CompanyName = styled.b`
  font-size: 16px;
  line-height: 22px;
`;

const AppStatus = styled.p`
  font-size: 12px;
  line-height: 22px;
  margin: 0;
`;

const StatusLabel = styled.span`
  margin-right: 5px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 0.5px solid ${({ theme }): string => theme.colors.greys.adminGreyMediumAlt};
  margin: 30px 0;
`;

interface AppSummaryProps {
  className?: string;
  appUuid: string;
}
export const AppSummary: React.FC<AppSummaryProps> = ({ className, appUuid }: AppSummaryProps): JSX.Element => {
  const [app] = useApp(appUuid) as EntityStateArray<App>;

  return (
    <StyledPaper className={className}>
      <StyledCompanyLogo url={app.company.logo_url} />
      <CompanyName>{app.company.name}</CompanyName>
      <AppStatus>
        <StatusLabel>
          <FormattedMessage id="appSummary.label.status" />
        </StatusLabel>
        <FormattedMessage id={`appStatuses.${app.status.toLowerCase()}`} />
      </AppStatus>
      <Divider />
      <AppInfo vertical={app.vertical.label} />
    </StyledPaper>
  );
};
