import React from 'react';
import styled from 'styled-components';

const Label = styled.p`
  color: ${({ theme }): string => theme.colors.greys.adminGreyDark};
  line-height: 22px;
  margin: 0;
`;
const Value = styled.p`
  padding: 16px;
  margin: 0;
`;

interface LabelFieldProps {
  label: string;
  value: React.ReactNode;
  dataCy?: string;
}
export const LabelField = ({ label, value, dataCy }: LabelFieldProps): JSX.Element => (
  <div>
    <Label data-cy={`${dataCy}-label`}>{label}</Label>
    <Value data-cy={`${dataCy}-value`}>{value}</Value>
  </div>
);
