/* eslint-disable react/prop-types */

import React from 'react';

interface BaseLayoutProps {
  getComponent: <T = React.FC>(name: string, isContainerComponent?: boolean) => T;
  errSelectors: Record<string, () => unknown>;
  specSelectors: Record<string, () => unknown>;
}

/*
  BaseLayout component copied from swagger library to allow inject ServerInputContainer component.
  Should be updated if swagger version will be updated.
*/
// eslint-disable-next-line react/prefer-stateless-function
export class BaseLayout extends React.Component<BaseLayoutProps> {
  render(): JSX.Element {
    const { errSelectors, specSelectors, getComponent } = this.props;

    const SvgAssets = getComponent('SvgAssets');
    const InfoContainer = getComponent('InfoContainer', true);
    const VersionPragmaFilter = getComponent<
      React.FC<{
        isSwagger2: boolean;
        isOAS3: boolean;
        alsoShow: React.ReactNode;
      }>
    >('VersionPragmaFilter');
    const Operations = getComponent('operations', true);
    const Models = getComponent('Models', true);
    const Row = getComponent<React.FC<{ className?: string }>>('Row');
    const Col = getComponent<React.FC<{ className?: string; mobile?: number; desktop?: number }>>('Col');
    const Errors = getComponent('errors', true);

    const ServerInputContainer = getComponent('ServerInputContainer', true);
    const SchemesContainer = getComponent('SchemesContainer', true);
    const AuthorizeBtnContainer = getComponent('AuthorizeBtnContainer', true);
    const FilterContainer = getComponent('FilterContainer', true);
    const isSwagger2 = specSelectors.isSwagger2() as boolean;
    const isOAS3 = specSelectors.isOAS3() as boolean;

    const isSpecEmpty = !specSelectors.specStr();

    const loadingStatus = specSelectors.loadingStatus();

    let loadingMessage = null;

    if (loadingStatus === 'loading') {
      loadingMessage = (
        <div className="info">
          <div className="loading-container">
            <div className="loading" />
          </div>
        </div>
      );
    }

    if (loadingStatus === 'failed') {
      loadingMessage = (
        <div className="info">
          <div className="loading-container">
            <h4 className="title">Failed to load API definition.</h4>
            <Errors />
          </div>
        </div>
      );
    }

    if (loadingStatus === 'failedConfig') {
      const lastErr = errSelectors.lastError() as Map<string, string>;
      const lastErrMsg = lastErr ? lastErr.get('message') : '';
      loadingMessage = (
        <div className="info failed-config">
          <div className="loading-container">
            <h4 className="title">Failed to load remote configuration.</h4>
            <p>{lastErrMsg}</p>
          </div>
        </div>
      );
    }

    if (!loadingMessage && isSpecEmpty) {
      loadingMessage = <h4>No API definition provided.</h4>;
    }

    if (loadingMessage) {
      return (
        <div className="swagger-ui">
          <div className="loading-container">{loadingMessage}</div>
        </div>
      );
    }

    const schemes = specSelectors.schemes() as { size: number } | null;

    const hasSchemes = schemes && schemes.size;
    const hasSecurityDefinitions = !!specSelectors.securityDefinitions();

    return (
      <div className="swagger-ui">
        <SvgAssets />
        <VersionPragmaFilter isSwagger2={isSwagger2} isOAS3={isOAS3} alsoShow={<Errors />}>
          <Errors />
          <Row className="information-container">
            <Col mobile={12}>
              <InfoContainer />
            </Col>
          </Row>

          {hasSchemes || hasSecurityDefinitions ? (
            <div className="scheme-container">
              <Col className="schemes wrapper" mobile={12}>
                <ServerInputContainer />
                {hasSchemes ? <SchemesContainer /> : null}
                {hasSecurityDefinitions ? <AuthorizeBtnContainer /> : null}
              </Col>
            </div>
          ) : null}

          <FilterContainer />

          <Row>
            <Col mobile={12} desktop={12}>
              <Operations />
            </Col>
          </Row>
          <Row>
            <Col mobile={12} desktop={12}>
              <Models />
            </Col>
          </Row>
        </VersionPragmaFilter>
      </div>
    );
  }
}
