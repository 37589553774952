import { useFormikContext, FormikHandlers } from 'formik';
import { useMemo } from 'react';
import { SelectItem } from 'components/form-select/select-item.interface';

interface FieldFormikInfo extends Pick<FormikHandlers, 'handleChange' | 'handleBlur'> {
  value: string;
  error: string;
}
export const useFieldFormikInfo = (name: string): FieldFormikInfo => {
  const { values, errors, handleChange, handleBlur } = useFormikContext<Record<string, string>>();
  const value = values[name] ?? '';
  const error = (errors[name] ?? '').toString();

  return {
    value,
    error,
    handleChange,
    handleBlur,
  };
};

export const useSelectedItem = (items: SelectItem[], value: string): SelectItem | null => {
  const itemsMap = useMemo(() => new Map(items.map((item) => [item.value, item])), [items]);

  return itemsMap.get(value) ?? null;
};
