import { createAsyncAction } from 'typesafe-actions';
import { App, AppSearchDto } from './types';

interface GetAppErrorDto {
  error: Error;
  uuid: string;
}

interface AppSearchParams {
  developerUuid: string;
  query: string;
}

interface AppSearchResponseDto extends AppSearchParams {
  apps: AppSearchDto[];
}

interface AppSearchErrorDto extends AppSearchParams {
  error: Error;
}

export const getApp = createAsyncAction('app/GET_APP_REQUEST', 'app/GET_APP_RESPONSE', 'app/GET_APP_FAILURE')<
  string,
  App,
  GetAppErrorDto
>();

export const getApps = createAsyncAction('app/GET_APPS_REQUEST', 'app/GET_APPS_RESPONSE', 'app/GET_APPS_FAILURE')<
  void,
  App[],
  Error
>();

export const searchApps = createAsyncAction(
  'app/SEARCH_REQUEST',
  'app/SEARCH_REQUEST_RESPONSE',
  'app/SEARCH_REQUEST_FAILURE',
)<AppSearchParams, AppSearchResponseDto, AppSearchErrorDto>();
