import React from 'react';
import { HeaderAndContentLayout, TwoColumnLayout } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import { AppConfigForm, AppConfigFormContent } from 'components/app-config';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useCreateButtonLoading, useCreateAppConfigHandler } from './create-app-config-page.hooks';
import { AppSummary } from 'components/app-summary';
import { ConfigHeader } from 'components/config-header';
import { StickySummarySection } from 'components/sticky-summary-section';
import { useCurrentAppConfigBlobDefinition } from 'store/app-config-blob-definitions/hooks';

const AppSummaryStyled = styled(AppSummary)`
  align-self: flex-start;
`;

const HeaderAndContentLayoutStyled = styled(HeaderAndContentLayout)`
  padding-top: 20px;
`;

const TwoColumnLayoutStyled = styled(TwoColumnLayout)`
  grid-gap: 120px;
  padding: 0 64px;
`;

export const CreateAppConfigPage = (): JSX.Element => {
  const intl = useIntl();
  const {
    params: { appUuid },
  } = useRouteMatch<{ appUuid: string }>();
  const loading = useCreateButtonLoading();
  const handleSubmit = useCreateAppConfigHandler(appUuid);
  const [, blobLoading] = useCurrentAppConfigBlobDefinition();

  return (
    <AppConfigForm onSubmit={handleSubmit}>
      <HeaderAndContentLayoutStyled>
        <ConfigHeader
          backLink="/apps"
          backLinkLabel={intl.formatMessage({ id: 'labels.applications' })}
          buttonText={intl.formatMessage({ id: 'buttons.submit' })}
          title={intl.formatMessage({ id: 'labels.configureApplication' })}
          loading={loading}
          isSaveDisabled={blobLoading}
        />
        <TwoColumnLayoutStyled>
          <AppConfigFormContent appUuid={appUuid} />
          <StickySummarySection>
            <AppSummaryStyled appUuid={appUuid} />
          </StickySummarySection>
        </TwoColumnLayoutStyled>
      </HeaderAndContentLayoutStyled>
    </AppConfigForm>
  );
};
