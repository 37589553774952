import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import messagesInEnglish from 'i18n/languages/en.json';

interface IntlProviderProps {
  children: React.ReactNode;
}

export const IntlProvider: React.FC<IntlProviderProps> = ({ children }: IntlProviderProps): JSX.Element => (
  <ReactIntlProvider messages={messagesInEnglish} locale="en" defaultLocale="en">
    {children}
  </ReactIntlProvider>
);
