import { Epic } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';

import * as appActions from 'store/apps/actions';
import * as appConfigActions from 'store/app-configs/actions';
import * as instancesActions from 'store/instances/actions';
import * as verticalAdaptersActions from 'store/vertical-adapters/actions';
import * as buildingsActions from 'store/buildings/actions';
import * as developersActions from 'store/developers/actions';
import * as notificationActions from 'store/notifications/actions';
import * as verticalDocsActions from 'store/vertical-docs/actions';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(
      isActionOf([
        appActions.getApp.failure,
        appActions.getApps.failure,
        appActions.searchApps.failure,

        appConfigActions.createAppConfig.failure,
        appConfigActions.getAppConfig.failure,
        appConfigActions.patchAppConfig.failure,

        instancesActions.createAppInstanceConfig.failure,
        instancesActions.deleteAppInstanceConfig.failure,
        instancesActions.getInstance.failure,
        instancesActions.getInstances.failure,
        instancesActions.patchInstance.failure,

        verticalAdaptersActions.getVerticalAdapters.failure,

        buildingsActions.getBuilding.failure,
        buildingsActions.searchBuildings.failure,

        developersActions.searchDevelopers.failure,

        verticalDocsActions.getVerticalDocs.failure,
      ]),
    ),
    map((action) => {
      const actionType = action.type.toLowerCase().replace('/', '.');

      return notificationActions.showNotification({
        translationKey: `notifications.errors.${actionType}`,
        type: 'error',
        dataCy: `error-notification-${actionType}`,
      });
    }),
  );

export const handleSuccessedRequests: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(
      isActionOf([
        appConfigActions.createAppConfig.success,
        appConfigActions.patchAppConfig.success,

        instancesActions.createAppInstanceConfig.success,
        instancesActions.deleteAppInstanceConfig.success,
        instancesActions.patchInstance.success,
      ]),
    ),
    map((action) => {
      const actionType = action.type.toLowerCase().replace('/', '.');

      return notificationActions.showNotification({
        translationKey: `notifications.success.${actionType}`,
        type: 'success',
        dataCy: `success-notification-${actionType}`,
      });
    }),
  );
