import React from 'react';
import logo from 'assets/images/HqO-text-dark-offset-blue.png';
import whiteLogo from 'assets/images/HqO-text-white.png';

interface LogoProps {
  name: string;
  className?: string;
}

interface Logos {
  [key: string]: React.ReactElement;
}

export const Logo = ({ className, name }: LogoProps): JSX.Element => {
  const allLogos: Logos = {
    logo: <img src={logo} className={className} alt="HqO" />,
    whiteLogo: <img src={whiteLogo} className={className} alt="HqO" />,
  };

  const selectedLogo = allLogos[name];

  return selectedLogo;
};
