import { Developer } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { searchDevelopers } from './actions';
import { getDevelopersSearchStateSelector, getDeveloperSelector } from './selectors';

export const useDevelopersSearch = (): [Developer[], boolean, string, (value: string) => void] => {
  const [query, setQuery] = useState('');
  const state = useSelector(getDevelopersSearchStateSelector(query));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchDevelopers.request(query));
  }, []);

  const handleSearchChange = useCallback(
    (value: string) => {
      const searchQuery = value ?? '';

      setQuery(searchQuery);
      dispatch(searchDevelopers.request(searchQuery));
    },
    [setQuery, dispatch, searchDevelopers],
  );

  return [state?.data ?? [], state?.loading ?? false, query, handleSearchChange];
};

export const useDeveloper = (uuid: string | null): Developer | null => useSelector(getDeveloperSelector(uuid ?? ''));
