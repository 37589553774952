import { AppsState } from './types';
import { createReducer } from 'typesafe-actions';
import * as appsActions from './actions';
import { combineReducers } from 'redux';

export const initialState: AppsState = {
  loading: false,
  error: null,
  data: {},
  searchCache: {},
  searchedApps: {},
};

const loading = createReducer(initialState.loading)
  .handleAction(appsActions.getApps.request, () => true)
  .handleAction(appsActions.getApps.success, () => false)
  .handleAction(appsActions.getApps.failure, () => false);

const error = createReducer(initialState.error)
  .handleAction(appsActions.getApps.request, () => null)
  .handleAction(appsActions.getApps.success, () => null)
  .handleAction(appsActions.getApps.failure, (_state, action) => action.payload);

const data = createReducer(initialState.data)
  .handleAction(appsActions.getApp.request, (state, action) => ({
    ...state,
    [action.payload]: { loading: true, data: null, error: null },
  }))
  .handleAction(appsActions.getApp.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: action.payload, error: null },
  }))
  .handleAction(appsActions.getApp.failure, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: null, error: action.payload.error },
  }))
  .handleAction(appsActions.getApps.success, (state, action) =>
    action.payload.reduce(
      (hash, app, index) => ({
        ...hash,
        [app.uuid]: {
          loading: false,
          data: app,
          error: null,
          order: index,
        },
      }),
      {},
    ),
  );

const searchCache = createReducer(initialState.searchCache)
  .handleAction(appsActions.searchApps.request, (state, action) => ({
    ...state,
    [action.payload.developerUuid]: {
      ...state[action.payload.developerUuid],
      [action.payload.query]: { loading: true, data: [], error: null },
    },
  }))
  .handleAction(appsActions.searchApps.success, (state, action) => ({
    ...state,
    [action.payload.developerUuid]: {
      ...state[action.payload.developerUuid],
      [action.payload.query]: { loading: false, data: action.payload.apps, error: null },
    },
  }))
  .handleAction(appsActions.searchApps.failure, (state, action) => ({
    ...state,
    [action.payload.developerUuid]: {
      ...state[action.payload.developerUuid],
      [action.payload.query]: { loading: false, data: [], error: action.payload.error },
    },
  }));

const searchedApps = createReducer(initialState.searchedApps).handleAction(
  appsActions.searchApps.success,
  (state, action) =>
    action.payload.apps.reduce(
      (searchedAppsCache, app) => ({
        ...searchedAppsCache,
        [app.uuid]: app,
      }),
      state,
    ),
);

export default combineReducers({
  data,
  loading,
  error,
  searchCache,
  searchedApps,
});
