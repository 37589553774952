import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { AppConfigState, AppConfig } from './types';

export const appConfigsState = (state: RootState): RootState['appConfigs'] => state.appConfigs;

export const createAppConfigsLoading = createSelector(
  appConfigsState,
  (state: RootState['appConfigs']): boolean => state.createAppConfigState.loading,
);

type AppConfigStateSelector = (state: RootState) => AppConfigState | null;
export const getAppConfigStateSelector = (appConfigUuid: string): AppConfigStateSelector =>
  createSelector(
    appConfigsState,
    (state: RootState['appConfigs']): AppConfigState | null => (state.configs[appConfigUuid] as AppConfigState) ?? null,
  );

type AppConfigUpdatingSelector = (state: RootState) => boolean;
export const getAppConfigUpdatingSelector = (appConfigUuid: string): AppConfigUpdatingSelector =>
  createSelector(
    getAppConfigStateSelector(appConfigUuid),
    (state: AppConfigState | null): boolean => !!state?.updating,
  );

type AppConfigSelector = (state: RootState) => AppConfig | null;
export const getAppConfigSelector = (appConfigUuid: string): AppConfigSelector =>
  createSelector(
    getAppConfigStateSelector(appConfigUuid),
    (state: AppConfigState | null): AppConfig | null => state?.data ?? null,
  );
