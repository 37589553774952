import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { AppInstanceConfigBlobDefinition } from './types';
import { EntityState } from 'shared/types';

export const appInstanceConfigBlobDefinitionsState = (
  state: RootState,
): RootState['appInstanceConfigBlobDefinitions'] => state.appInstanceConfigBlobDefinitions;

type AppInstanceConfigBlobDefinitionStateSelector = (
  state: RootState,
) => EntityState<AppInstanceConfigBlobDefinition | null> | null;

export const getAppInstanceConfigBlobDefinitionStateSelector = (
  appConfigUuid: string,
): AppInstanceConfigBlobDefinitionStateSelector =>
  createSelector(
    appInstanceConfigBlobDefinitionsState,
    (state: RootState['appInstanceConfigBlobDefinitions']): ReturnType<AppInstanceConfigBlobDefinitionStateSelector> =>
      state.data[appConfigUuid] ?? null,
  );
