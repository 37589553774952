import React from 'react';

interface DownloadUrlProps {
  url: string;
  specSelectors: Record<string, <T = string | null>() => T>;
}
export const DownloadUrl = ({ url, specSelectors }: DownloadUrlProps): JSX.Element => {
  const info: { get: (path: string) => string | null } = specSelectors.info();
  const fileName = `${info.get('title')}-${info.get('version')}-openapi.json`.toLowerCase().replace(/\s/, '-');

  return (
    <a className="download-spec-link" target="_blank" rel="noopener noreferrer" href={url}>
      <span className="url">{fileName}</span>
    </a>
  );
};
