import { getStaticContentUrl } from 'utils/get-static-content-url.util';

const DEFAULT_AVATAR = 'uploads/default_avatar.png';
const UPLOADS_FOLDER = 'uploads';
const DEFAULT_IMAGES_FOLDER = 'default_images';
const ASSETS_FOLDER = 'assets';

export const getImageUrl = (path?: string | null): string => {
  const staticPath = getStaticContentUrl();

  if (!path) {
    return `${staticPath}${DEFAULT_AVATAR}`;
  }

  if (path.startsWith(UPLOADS_FOLDER) || path.startsWith(DEFAULT_IMAGES_FOLDER) || path.startsWith(ASSETS_FOLDER)) {
    return `${staticPath}${path}`;
  }

  return path;
};
