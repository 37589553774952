import React from 'react';
import styled from 'styled-components';
import { HeaderAndContentLayout, PageHeader, HeaderTitle } from '@hqo/react-components-library';
import { FormattedMessage } from 'react-intl';
import { TableWrapper } from 'components/table-wrapper';
import { ApplicationsTable } from 'components/applications-table';
import { ConfigureApplicationTableHeader } from 'components/configure-application-table-header';
import { useApps } from 'store/apps/hooks';
import { getZIndex } from 'shared/z-index';

const PageHeaderStyled = styled(PageHeader)`
  z-index: ${getZIndex('header')};
  padding: 92px 64px 50px 64px;
`;

export const ApplicationsPage: React.FC = (): JSX.Element => {
  const [apps, loading] = useApps();

  return (
    <HeaderAndContentLayout>
      <PageHeaderStyled>
        <HeaderTitle>
          <FormattedMessage id="labels.applications" />
        </HeaderTitle>
      </PageHeaderStyled>
      {!loading && (
        <TableWrapper>
          <ConfigureApplicationTableHeader />
          <ApplicationsTable apps={apps} />
        </TableWrapper>
      )}
    </HeaderAndContentLayout>
  );
};
