import React from 'react';

import { Logo } from 'components/logo';

import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledLogo = styled(Logo)`
  width: 45%;
  margin-bottom: 25px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

export const Loading: React.FC = (): JSX.Element => (
  <LoadingContainer>
    <StyledLogo name="logo" />
    <h2>
      <FormattedMessage id="info.loading" />
    </h2>
  </LoadingContainer>
);
