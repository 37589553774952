import { useFormikContext, FormikHandlers } from 'formik';

interface FieldFormikInfo extends Pick<FormikHandlers, 'handleChange' | 'handleBlur'> {
  value: string;
  error: string;
}
export const useFieldFormikInfo = (name: string): FieldFormikInfo => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<Record<string, string>>();
  const value = values[name] ?? '';
  const error = touched[name] ? (errors[name] ?? '').toString() : '';

  return {
    value,
    error,
    handleChange,
    handleBlur,
  };
};
