import React from 'react';
import styled from 'styled-components';

const StickySummary = styled.section`
  position: sticky;
  top: 195px;
  margin-bottom: 32px;
`;

interface StickySummaryProps {
  children: React.ReactNode;
  className?: string;
}

export const StickySummarySection: React.FC<StickySummaryProps> = ({
  className,
  children,
}: StickySummaryProps): JSX.Element => (
  <section>
    <StickySummary className={className}>{children}</StickySummary>
  </section>
);
