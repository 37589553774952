/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { LabelField } from 'components/label-field';
import { Input } from '@hqo/react-components-library';
import { useFieldFormikInfo } from './form-input.hooks';
import { useFieldTranslations } from 'hooks/form-field';

type StringPropertyNames<T> = { [K in keyof T]: T[K] extends string | null ? K : never }[keyof T];

interface FormInputProps<T extends object> {
  sectionTranslationKey: string;
  name: StringPropertyNames<T>;
  readonly?: boolean;
  dataCy?: string;
  required?: boolean;
}

export const FormInput = <T extends object>({
  sectionTranslationKey,
  name,
  readonly,
  dataCy,
  required,
}: FormInputProps<T>): JSX.Element => {
  const fieldName = name.toString();
  const { label, placeholder } = useFieldTranslations(sectionTranslationKey, fieldName);
  const { value, error, handleChange, handleBlur } = useFieldFormikInfo(fieldName);

  if (readonly) {
    return <LabelField label={label} value={value} dataCy={dataCy} />;
  }

  return (
    <Input
      label={label}
      placeholder={placeholder}
      variant={error ? 'error' : 'master'}
      name={fieldName}
      required={required}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={error}
      dataCy={`${dataCy}-input`}
    />
  );
};
