import { BuildingsState } from './types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { combineReducers } from 'redux';

export const initialState: BuildingsState = {
  searchCache: {},
  buildings: {},
};

const searchCache = createReducer(initialState.searchCache)
  .handleAction(actions.searchBuildings.request, (state, action) => ({
    ...state,
    [action.payload]: { loading: true, data: [], error: null },
  }))
  .handleAction(actions.searchBuildings.success, (state, action) => ({
    ...state,
    [action.payload.query]: { loading: false, data: action.payload.buildings, error: null },
  }))
  .handleAction(actions.searchBuildings.failure, (state, action) => ({
    ...state,
    [action.payload.query]: { loading: false, data: [], error: action.payload.error },
  }));

const buildings = createReducer(initialState.buildings)
  .handleAction(actions.searchBuildings.success, (state, action) =>
    action.payload.buildings.reduce(
      (buildingsState, building) => ({
        ...buildingsState,
        [building.uuid]: building,
      }),
      state,
    ),
  )
  .handleAction(actions.getBuilding.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: action.payload,
  }));

export default combineReducers({ searchCache, buildings });
