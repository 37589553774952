import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConfigFormValues } from 'components/app-config';
import { getAppConfigUpdatingSelector, getAppConfigSelector } from 'store/app-configs/selectors';
import { patchAppConfig } from 'store/app-configs/actions';

export const useButtonLoading = (configUuid: string): boolean => useSelector(getAppConfigUpdatingSelector(configUuid));

type SaveAppConfigHandler = (appConfig: AppConfigFormValues) => void;
export const useSaveAppConfigHandler = (appUuid: string, appConfigUuid: string): SaveAppConfigHandler => {
  const dispatch = useDispatch();

  return useCallback(
    (appConfig: AppConfigFormValues): void => {
      dispatch(patchAppConfig.request({ appUuid, appConfigUuid, appConfig }));
    },
    [appUuid, dispatch],
  );
};

export const useAppConfigInitialValues = (configUuid: string): Partial<AppConfigFormValues> => {
  const appConfig = useSelector(getAppConfigSelector(configUuid));

  if (!appConfig) {
    return {};
  }

  return {
    base_url: appConfig.base_url,
    config: appConfig.config,
    enabled: appConfig.enabled,
    version: appConfig.version,
    vertical_adapter_uuid: appConfig.vertical_adapter_uuid,
    validated_at: appConfig.validated_at,
  };
};
