import React from 'react';
import { LabelField } from 'components/label-field';
import { useFieldTranslations } from 'hooks/form-field';
import { getAppInstanceSelector } from 'store/instances/selectors';
import { useSelector } from 'react-redux';

interface AppConfigViewSectionProps {
  appInstanceConfigUuid: string;
}

export const AppConfigViewSection = ({ appInstanceConfigUuid }: AppConfigViewSectionProps): JSX.Element => {
  const developerLabels = useFieldTranslations('appInstanceInformationSection', 'developer');
  const appLabels = useFieldTranslations('appInstanceInformationSection', 'app');
  const appInstance = useSelector(getAppInstanceSelector(appInstanceConfigUuid));
  const developerName = appInstance?.company?.name;
  const appName = appInstance?.app?.name;

  return (
    <>
      <LabelField label={developerLabels.label} value={developerName} />
      <LabelField label={appLabels.label} value={appName} />
    </>
  );
};
