import { VerticalsAdaptersState } from './types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const initialState: VerticalsAdaptersState = {};

export default createReducer(initialState)
  .handleAction(actions.getVerticalAdapters.request, (state, action) => ({
    ...state,
    [action.payload]: { loading: true, data: [], error: null },
  }))
  .handleAction(actions.getVerticalAdapters.success, (state, action) => ({
    ...state,
    [action.payload.verticalUuid]: { loading: false, data: action.payload.adapters, error: null },
  }))
  .handleAction(actions.getVerticalAdapters.failure, (state, action) => ({
    ...state,
    [action.payload.verticalUuid]: { loading: false, data: [], error: action.payload.error },
  }));
