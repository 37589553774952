import { App, AppSearchDto } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { getAppStateSelector, appsState, getAppsSearchStateSelector, getSearchedAppSelector } from './selectors';
import { getApp, getApps, searchApps } from './actions';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { EntityStateArray } from 'shared/types';
import { useEntityByUuid } from 'shared/hooks/use-entity-by-uuid.hook';

export const useApp = (appUuid: string): EntityStateArray<App | null> =>
  useEntityByUuid<App | null>(appUuid, getAppStateSelector, getApp.request);

export const useApps = (): EntityStateArray<App[]> => {
  const state = useSelector(appsState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApps.request());
  }, []);

  const apps = useMemo(
    () =>
      Object.values(state.data)
        .sort((app1, app2) => (app1.order ?? 0) - (app2.order ?? 0))
        .map(({ data }) => data)
        .filter(Boolean) as App[],
    [state.data],
  );

  return [apps, !!state.loading, !!state.error];
};

export const useAppsSearch = (
  developerUuid: string | null,
): [AppSearchDto[], boolean, string, (value: string) => void] => {
  const [query, setQuery] = useState('');
  const state = useSelector(getAppsSearchStateSelector(developerUuid ?? '', query));
  const dispatch = useDispatch();

  useEffect(() => {
    if (developerUuid) {
      dispatch(searchApps.request({ developerUuid, query }));
    }
  }, [developerUuid, searchApps]);

  const handleSearchChange = useCallback(
    (value: string) => {
      const searchQuery = value ?? '';

      setQuery(searchQuery);

      if (developerUuid) {
        dispatch(searchApps.request({ developerUuid, query: searchQuery }));
      }
    },
    [setQuery, dispatch, searchApps, developerUuid],
  );

  return [state?.data ?? [], state?.loading ?? false, query, handleSearchChange];
};

export const useSearchedApp = (appUuid: string | null): AppSearchDto | null =>
  useSelector(getSearchedAppSelector(appUuid ?? ''));
