import { EntityStateArray, EntityState } from 'shared/types';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from 'store';

export const useEntityByUuid = <T>(
  uuid: string,
  selector: (uuid: string) => (state: RootState) => EntityState<T | null> | null,
  getEntityAction: (uuid: string) => unknown,
): EntityStateArray<T | null> => {
  const state = useSelector(selector(uuid));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state && uuid) {
      dispatch(getEntityAction(uuid));
    }
  }, [state, uuid, getEntityAction, dispatch]);

  if (!state) {
    return [null, !!uuid, false];
  }

  return [state.data, !!state.loading, !!state.error];
};
