import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { Developer } from './types';

export const searchDevelopers: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.searchDevelopers.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const query = action.payload;

      return apiClient(state)
        .getDevelopers(query)
        .pipe(
          map(({ response }: AjaxResponse) =>
            actions.searchDevelopers.success({
              query,
              developers: response as Developer[],
            }),
          ),
          catchError((error: Error) => of(actions.searchDevelopers.failure({ query, error }))),
        );
    }),
  );
