import { combineEpics } from 'redux-observable';

import * as userEpics from 'store/user/epics';
import * as appsEpics from 'store/apps/epics';
import * as buildingsEpics from 'store/buildings/epics';
import * as appConfigssEpics from 'store/app-configs/epics';
import * as verticalAdaptersEpics from 'store/vertical-adapters/epics';
import * as instancesEpics from 'store/instances/epics';
import * as developersEpics from 'store/developers/epics';
import * as appConfigBlobDefinitionsEpics from 'store/app-config-blob-definitions/epics';
import * as appInstanceConfigBlobDefinitionsEpics from 'store/app-instance-config-blob-definitions/epics';
import * as notificationsEpics from 'store/notifications/epics';
import * as verticalDocsEpics from 'store/vertical-docs/epics';

export default combineEpics(
  ...Object.values(userEpics),
  ...Object.values(appsEpics),
  ...Object.values(buildingsEpics),
  ...Object.values(appConfigssEpics),
  ...Object.values(verticalAdaptersEpics),
  ...Object.values(instancesEpics),
  ...Object.values(developersEpics),
  ...Object.values(appConfigBlobDefinitionsEpics),
  ...Object.values(appInstanceConfigBlobDefinitionsEpics),
  ...Object.values(notificationsEpics),
  ...Object.values(verticalDocsEpics),
);
