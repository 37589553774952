import { createAsyncAction } from 'typesafe-actions';
import { AppConfig, CreateAppConfigPayload, PatchAppConfigPayload } from './types';

interface CreateAppConfigDto {
  appUuid: string;
  appConfig: CreateAppConfigPayload;
}

interface PatchAppConfigDto {
  appUuid: string;
  appConfigUuid: string;
  appConfig: PatchAppConfigPayload;
}

interface GetAppConfigDto {
  appUuid: string;
  appConfigUuid: string;
}

interface AppConfigErrorDto {
  uuid: string;
  error: Error;
}

export const createAppConfig = createAsyncAction(
  'app-config/CREATE_APP_CONFIG_REQUEST',
  'app-config/CREATE_APP_CONFIG_RESPONSE',
  'app-config/CREATE_APP_CONFIG_FAILURE',
)<CreateAppConfigDto, AppConfig, Error>();

export const patchAppConfig = createAsyncAction(
  'app-config/PATCH_APP_CONFIG_REQUEST',
  'app-config/PATCH_APP_CONFIG_RESPONSE',
  'app-config/PATCH_APP_CONFIG_FAILURE',
)<PatchAppConfigDto, AppConfig, AppConfigErrorDto>();

export const getAppConfig = createAsyncAction(
  'app-config/GET_APP_CONFIG_REQUEST',
  'app-config/GET_APP_CONFIG_RESPONSE',
  'app-config/GET_APP_CONFIG_FAILURE',
)<GetAppConfigDto, AppConfig, AppConfigErrorDto>();
