import React from 'react';
import styled from 'styled-components';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { HqoSwaggerPlugins } from 'components/swagger-docs/plugins';

const StyledSwaggerUIWrapper = styled.div`
  .scheme-container {
    background-color: ${({ theme }): string => theme.colors.universal.transparent};
    box-shadow: none;
    padding: 0;
  }

  .info {
    margin: 32px 0 20px 0;
  }

  .swagger-ui .wrapper {
    padding: 0 64px;
    max-width: none;
  }

  hgroup.main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .download-spec-link {
      margin-right: 10px;
    }

    .download-spec-link .url {
      font-size: 14px;
    }
  }
`;

interface SwaggerDocsProps {
  specUrl?: string | null;
}

export const SwaggerDocs = ({ specUrl }: SwaggerDocsProps): JSX.Element | null => {
  if (!specUrl) {
    return null;
  }

  return (
    <StyledSwaggerUIWrapper>
      <SwaggerUI url={specUrl} plugins={HqoSwaggerPlugins} />
    </StyledSwaggerUIWrapper>
  );
};
