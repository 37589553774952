import React from 'react';
import { Table, TableBody, TableHead } from '@hqo/react-components-library';
import { TotalRowsFooter } from 'components/total-rows-footer';
import { App } from 'store/apps/types';
import { ApplicationsTableRow } from './applications-table-row';
import { ApplicationsTableHeaderRow } from './applications-table-header-row';

interface ApplicationsTableProps {
  apps: App[];
}
export const ApplicationsTable = ({ apps }: ApplicationsTableProps): JSX.Element => (
  <>
    <Table>
      <TableHead>
        <ApplicationsTableHeaderRow />
      </TableHead>
      <TableBody>
        {apps.map((app) => (
          <ApplicationsTableRow key={app.uuid} app={app} />
        ))}
      </TableBody>
    </Table>
    <TotalRowsFooter count={apps.length} />
  </>
);
