import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { CreateAppInstanceConfigPage } from 'pages/create-app-instance-config-page';
import { InstancesRedirect } from 'components/router/instances-route/instances-redirect';

export const CreateInstanceRoute = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={CreateAppInstanceConfigPage} exact />
      <Route component={InstancesRedirect} />
    </Switch>
  );
};
