import React from 'react';
import { Paper } from '@hqo/react-components-library';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Description } from './description';

const PaperStyled = styled(Paper)`
  padding: 24px;

  & > * {
    margin-bottom: 20px;
  }

  & > br {
    content: '';
    line-height: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.b`
  margin-bottom: 4px;
  line-height: 22px;
`;

interface FormSectionProps {
  className?: string;
  children: React.ReactNode;
  sectionTranslationKey: string;
  instructionsUrl?: string | null;
}

export const FormSection: React.FC<FormSectionProps> = ({
  className,
  children,
  sectionTranslationKey,
  instructionsUrl,
}: FormSectionProps): JSX.Element => (
  <section className={className}>
    <Title>
      <FormattedMessage id={`${sectionTranslationKey}.title`} />
    </Title>
    <Description sectionTranslationKey={sectionTranslationKey} instructionsUrl={instructionsUrl} />
    <PaperStyled>{children}</PaperStyled>
  </section>
);
