import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap, mapTo } from 'rxjs/operators';
import { replace } from 'connected-react-router';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { User } from 'store/user/types';
import * as userActions from './actions';
import { RootDependencies } from 'store/dependencies';
import qs from 'qs';
import { hasAccessPermissions } from 'store/user/utils';
import { UnauthorizedError } from 'store/user/errors';

const UNAUTHORIZED_ERROR_CODE = 401;
const NOT_FOUND_ERROR_CODE = 404;
const BAD_REQUEST_ERROR_CODE = 400;
const INTERNAL_SERVER_ERROR_CODE = 500;

export const externalLogin: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.externalLogin.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {
        payload: { authToken, farmUrl },
      } = action;

      return apiClient(state, farmUrl, authToken)
        .getCurrentUser()
        .pipe(
          map(({ response }: AjaxResponse) => {
            const user = response as User;

            if (hasAccessPermissions(user)) {
              return userActions.externalLogin.success({
                user,
                farmUrl,
                token: authToken as string,
              });
            }

            return userActions.externalLogin.failure(new UnauthorizedError());
          }),
          catchError((error: Error) => {
            if (
              error instanceof AjaxError &&
              error.status >= BAD_REQUEST_ERROR_CODE &&
              error.status < INTERNAL_SERVER_ERROR_CODE
            ) {
              return of(userActions.externalLogin.failure(new UnauthorizedError()));
            }

            return of(userActions.externalLogin.failure(error));
          }),
        );
    }),
  );

export const externalLoginRedirect: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(userActions.externalLogin.success)),
    withLatestFrom(state$),
    map(([, state]) => {
      const search = state.router?.location?.search ?? '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { authToken, farmUrl, ...restSearch } = qs.parse(search.replace(/^\?/, ''));
      const newSearchString = qs.stringify(restSearch);
      const path = state.router?.location?.pathname ?? '/';

      return replace(newSearchString ? `${path}?${newSearchString}` : path);
    }),
  );

export const externalLoginFailureRedirect: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(filter(isActionOf(userActions.externalLogin.failure)), mapTo(replace('/')));

export const getCurrentUser: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.getCurrentUser.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      apiClient(state)
        .getCurrentUser()
        .pipe(
          map(({ response: user }: AjaxResponse) => {
            if (hasAccessPermissions(user)) {
              return userActions.getCurrentUser.success({
                user,
              });
            }

            return userActions.getCurrentUser.failure(new UnauthorizedError());
          }),
          catchError((error: Error) => {
            if (
              error instanceof AjaxError &&
              (error.status === UNAUTHORIZED_ERROR_CODE || error.status === NOT_FOUND_ERROR_CODE)
            ) {
              return of(userActions.getCurrentUser.failure(new UnauthorizedError()));
            }

            return of(userActions.getCurrentUser.failure(error));
          }),
        ),
    ),
  );
