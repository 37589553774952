import { AppInstanceConfigBlobDefinition } from './types';
import { getAppInstanceConfigBlobDefinitionStateSelector, appInstanceConfigBlobDefinitionsState } from './selectors';
import { getAppInstanceConfigBlobDefinition } from './actions';
import { EntityStateArray } from 'shared/types';
import { useEntityByUuid } from 'shared/hooks/use-entity-by-uuid.hook';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useAppInstanceConfigBlobDefinition = (
  appConfigUuid: string,
): EntityStateArray<AppInstanceConfigBlobDefinition | null> =>
  useEntityByUuid<AppInstanceConfigBlobDefinition>(
    appConfigUuid,
    getAppInstanceConfigBlobDefinitionStateSelector,
    getAppInstanceConfigBlobDefinition.request,
  );

export const useCurrentAppInstanceConfigBlobDefinition = (): EntityStateArray<AppInstanceConfigBlobDefinition | null> => {
  const { currentDefinitionKey, data } = useSelector(appInstanceConfigBlobDefinitionsState);
  const state = (currentDefinitionKey && data[currentDefinitionKey]) ?? null;

  return useMemo(() => {
    if (!state) {
      return [null, !!currentDefinitionKey, false];
    }

    return [state.data, state.loading, !!state.error];
  }, [state]);
};
