import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { EditAppConfigPage } from 'pages/edit-app-config-page';
import { AppsRedirect } from 'components/router/apps-route/apps-redirect';
import { useApp } from 'store/apps/hooks';
import { useAppConfig } from 'store/app-configs/hooks';
import { EditAppConfigRouteParams } from 'components/router/apps-route/app-route/config-route/config-route.interfaces';
import { Loading } from 'components/loading';

export const EditConfigRoute = (): JSX.Element => {
  const {
    path,
    params: { appUuid, configUuid },
  } = useRouteMatch<EditAppConfigRouteParams>();
  const [, appLoading, appError] = useApp(appUuid);
  const [, appConfigLoading, appConfigError] = useAppConfig(appUuid, configUuid);

  if (appLoading || appConfigLoading) {
    return <Loading />;
  }

  if (appError || appConfigError) {
    return <AppsRedirect />;
  }

  return (
    <Switch>
      <Route path={path} component={EditAppConfigPage} exact />
      <Route component={AppsRedirect} />
    </Switch>
  );
};
