import React from 'react';
import { AppInformationSection } from './app-information-section';
import { AppConfigurationSection } from './app-configuration-section';
import styled from 'styled-components';
import { AppConfigFormValues } from 'components/app-config/app-config-form-values.interface';
import { selectAppConfigBlobDefinition } from 'store/app-config-blob-definitions/actions';
import { useCurrentAppConfigBlobDefinition } from 'store/app-config-blob-definitions/hooks';
import { useConfigDefaultValues } from 'hooks/use-config-default-values';
import { useBlobChangeHandler } from 'hooks/use-blob-change-handler';

const AppInformationSectionStyled = styled(AppInformationSection)`
  margin-bottom: 40px;
`;

const AppConfigurationSectionStyled = styled(AppConfigurationSection)`
  margin-bottom: 40px;
`;

interface AppConfigFormContentProps {
  isEditMode?: boolean;
  appUuid: string;
}

export const AppConfigFormContent: React.FC<AppConfigFormContentProps> = ({
  appUuid,
  isEditMode,
}: AppConfigFormContentProps): JSX.Element => {
  const [definition, loading] = useCurrentAppConfigBlobDefinition();

  useBlobChangeHandler<AppConfigFormValues>('vertical_adapter_uuid', selectAppConfigBlobDefinition, isEditMode);
  useConfigDefaultValues(definition, loading);

  return (
    <section>
      <AppInformationSectionStyled appUuid={appUuid} isEditMode={isEditMode} />
      <AppConfigurationSectionStyled isEditMode={isEditMode} />
    </section>
  );
};
