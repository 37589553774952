import { EntityStateArray } from '../../shared/types';
import { Instance, DeleteAppInstanceConfigState } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import {
  getInstances,
  getInstance,
  showConfirmDeletionDialog,
  cancelDeletionDialog,
  deleteAppInstanceConfig,
} from './actions';
import { instancesState, getInstanceStateSelector, deleteAppInstanceConfigStateSelector } from './selectors';
import { useEntityByUuid } from 'shared/hooks/use-entity-by-uuid.hook';

export const useInstance = (uuid: string): EntityStateArray<Instance | null> =>
  useEntityByUuid<Instance | null>(uuid, getInstanceStateSelector, getInstance.request);

export const useInstances = (): EntityStateArray<Instance[]> => {
  const state = useSelector(instancesState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstances.request());
  }, []);

  const instances = useMemo(
    () =>
      Object.values(state.data)
        .sort((instance1, instance2) => (instance1.order ?? 0) - (instance2.order ?? 0))
        .map(({ data }) => data)
        .filter(Boolean) as Instance[],
    [state.data],
  );

  return [instances, !!state.loading, !!state.error];
};

export const useDeleteInstanceConfigState = (): DeleteAppInstanceConfigState =>
  useSelector(deleteAppInstanceConfigStateSelector);

export const useShowDeleteInstanceConfigDialogHandler = (instanceUuid: string): React.MouseEventHandler => {
  const dispatch = useDispatch();

  return useCallback((): void => {
    dispatch(showConfirmDeletionDialog(instanceUuid));
  }, [dispatch, instanceUuid]);
};

export const useCancelDeleteInstanceConfigHandler = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback((): void => {
    dispatch(cancelDeletionDialog());
  }, [dispatch]);
};

export const useDeleteInstanceConfigHandler = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback((): void => {
    dispatch(deleteAppInstanceConfig.request());
  }, [dispatch]);
};
