import React from 'react';
import { Building } from 'store/buildings/types';
import { useBuildingsSearch } from 'store/buildings/hooks';
import { DynamicSelect } from 'components/dynamic-select';

interface BuildingSelectProps {
  label: string;
  placeholder: string;
  selectedBuilding: Building | null;
  name: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}
export const BuildingSelect = ({
  label,
  placeholder,
  selectedBuilding,
  name,
  onChange: handleChange,
}: BuildingSelectProps): JSX.Element => {
  const [buildings, loading, query, handleSearch] = useBuildingsSearch();

  return (
    <DynamicSelect
      label={label}
      placeholder={placeholder}
      selectedItem={selectedBuilding}
      name={name}
      dataCy="building"
      items={buildings}
      loading={loading}
      query={query}
      handleSearch={handleSearch}
      onChange={handleChange}
    />
  );
};
