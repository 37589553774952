import React from 'react';
import { TableRow, TableCell, ActionsTableCell, Button, Pencil } from '@hqo/react-components-library';
import { App, AppStatus } from 'store/apps/types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

const StyledButton = styled(Button)`
  margin: auto;
  padding: 8px 10px;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const EditIcon = styled(Pencil)`
  width: 18px;
  height: 18px;
`;

interface ApplicationsTableRowProps {
  app: App;
}
export const ApplicationsTableRow = ({ app }: ApplicationsTableRowProps): JSX.Element => {
  const intl = useIntl();
  const isNewApp = app.status === AppStatus.NEW;
  const typeTranslationId = app.type ? `appTypes.${app.type.toLowerCase()}` : 'appTypes.noType';
  const latestConfigUuid = app.config_uuids ? [...app.config_uuids].pop() : '';
  const appActionUrl = isNewApp ? `apps/${app.uuid}/configs` : `apps/${app.uuid}/configs/${latestConfigUuid}`;

  return (
    <TableRow data-cy={`app-row-${app.uuid}`}>
      <TableCell data-cy="app-cell-date">{moment(app.created_at).format('MM-DD-YYYY').toString()}</TableCell>
      <TableCell data-cy="app-cell-company">{app.company.name}</TableCell>
      <TableCell data-cy="app-cell-name">{app.name}</TableCell>
      <TableCell data-cy="app-cell-vertical">{app.vertical.label}</TableCell>
      <TableCell data-cy="app-cell-type">
        <FormattedMessage id={typeTranslationId} />
      </TableCell>
      <TableCell data-cy="app-cell-status">
        <FormattedMessage id={`appStatuses.${app.status.toLowerCase()}`} />
      </TableCell>
      <ActionsTableCell data-cy="app-cell-actions">
        <StyledLink to={appActionUrl}>
          {isNewApp ? (
            <StyledButton text={intl.formatMessage({ id: 'labels.configure' })} size="sm" dataCy="app-config-link" />
          ) : (
            <EditIcon variant="adminGreyDark" data-cy="app-edit-link" />
          )}
        </StyledLink>
      </ActionsTableCell>
    </TableRow>
  );
};
