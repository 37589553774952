import { AppConfigsState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const initialState: AppConfigsState = {
  createAppConfigState: {
    loading: false,
    error: null,
  },
  configs: {},
};

const createAppConfigState = createReducer(initialState.createAppConfigState)
  .handleAction(actions.createAppConfig.request, () => ({
    loading: true,
    error: null,
  }))
  .handleAction(actions.createAppConfig.success, () => ({
    loading: false,
    error: null,
  }))
  .handleAction(actions.createAppConfig.failure, (state, action) => ({
    loading: false,
    error: action.payload,
  }));

const configs = createReducer(initialState.configs)
  .handleAction(actions.getAppConfig.request, (state, action) => ({
    ...state,
    [action.payload.appConfigUuid]: { loading: true, data: null, error: null },
  }))
  .handleAction(actions.getAppConfig.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: action.payload, error: null },
  }))
  .handleAction(actions.getAppConfig.failure, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: null, error: action.payload.error },
  }))
  .handleAction(actions.patchAppConfig.request, (state, action) => ({
    ...state,
    [action.payload.appConfigUuid]: {
      updating: true,
      loading: state[action.payload.appConfigUuid]?.loading ?? false,
      data: state[action.payload.appConfigUuid]?.data ?? null,
      error: null,
    },
  }))
  .handleAction(actions.patchAppConfig.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: {
      loading: false,
      updating: false,
      data: action.payload,
      error: null,
    },
  }))
  .handleAction(actions.patchAppConfig.failure, (state, action) => ({
    ...state,
    [action.payload.uuid]: {
      loading: false,
      updating: false,
      data: null,
      error: action.payload.error,
    },
  }))
  .handleAction(actions.createAppConfig.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: action.payload, error: null },
  }));

export default combineReducers({
  createAppConfigState,
  configs,
});
