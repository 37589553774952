import React from 'react';
import { FormSection } from 'components/form-section';
import { BuildingFormSelect } from './building-form-select';
import { AppConfigSection } from './app-config-section';

interface AppInstanceInformationSectionProps {
  className?: string;
  appInstanceConfigUuid?: string;
}

export const AppInstanceInformationSection: React.FC<AppInstanceInformationSectionProps> = ({
  className,
  appInstanceConfigUuid,
}: AppInstanceInformationSectionProps): JSX.Element => (
  <FormSection className={className} sectionTranslationKey="appInstanceInformationSection">
    <BuildingFormSelect isEditMode={!!appInstanceConfigUuid} />
    <AppConfigSection appInstanceConfigUuid={appInstanceConfigUuid} />
  </FormSection>
);
