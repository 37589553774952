import { ApiIndicator } from 'components/api-indicator';
import styled, { createGlobalStyle } from 'styled-components';

export const RootStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.join()};
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
    background-color: ${({ theme }) => theme.colors.greys.adminGreyLight};
  }
`;

export const StyledApiIndicator = styled(ApiIndicator)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;
