import React, { useMemo } from 'react';
import { FormRenderer, FormRendererProps } from '@hqo/dynamic-form-ui';
import { FormInput } from 'components/form-input';
import { AppConfigFormValues } from 'components/app-config/app-config-form-values.interface';
import { FormSection } from 'components/form-section';
import { useBlobDefinition } from 'components/app-config/hooks/use-blob-definition.hook';
import { BlobsLoader } from 'components/blobs-loaders';

interface AppConfigurationSectionProps {
  className?: string;
  isEditMode?: boolean;
}

export const AppConfigurationSection: React.FC<AppConfigurationSectionProps> = ({
  className,
  isEditMode,
}: AppConfigurationSectionProps): JSX.Element => {
  const [definition, loading] = useBlobDefinition();
  const showBlobsSection = !!definition && !loading;
  const config = useMemo(
    (): FormRendererProps['config'] => ({
      parentKey: 'config',
      isEditMode,
    }),
    [isEditMode],
  );

  return (
    <FormSection
      className={className}
      sectionTranslationKey="appConfigurationSection"
      instructionsUrl={definition?.instructions_url}
    >
      <FormInput<AppConfigFormValues>
        sectionTranslationKey="appConfigurationSection"
        name="base_url"
        dataCy="base-url"
      />
      <FormInput<AppConfigFormValues>
        sectionTranslationKey="appConfigurationSection"
        name="version"
        readonly={isEditMode}
        dataCy="version"
        required
      />
      {loading && <BlobsLoader />}
      {showBlobsSection && <FormRenderer schema={definition} config={config} />}
    </FormSection>
  );
};
