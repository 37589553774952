import { AppConfigBlobDefinition } from './types';
import { getAppConfigBlobDefinitionStateSelector, appConfigBlobDefinitionsState } from './selectors';
import { getAppConfigBlobDefinition } from './actions';
import { EntityStateArray } from 'shared/types';
import { useEntityByUuid } from 'shared/hooks/use-entity-by-uuid.hook';
import { useSelector } from 'react-redux';

export const useAppConfigBlobDefinition = (adapterUuid: string): EntityStateArray<AppConfigBlobDefinition | null> =>
  useEntityByUuid<AppConfigBlobDefinition>(
    adapterUuid,
    getAppConfigBlobDefinitionStateSelector,
    getAppConfigBlobDefinition.request,
  );

export const useCurrentAppConfigBlobDefinition = (): EntityStateArray<AppConfigBlobDefinition | null> => {
  const { currentDefinitionKey, data } = useSelector(appConfigBlobDefinitionsState);
  const state = (currentDefinitionKey && data[currentDefinitionKey]) ?? null;

  if (!state) {
    return [null, !!currentDefinitionKey, false];
  }

  return [state.data, state.loading, !!state.error];
};
