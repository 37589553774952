import { SelectItem } from 'components/form-select/select-item.interface';
import { useVerticalAdapters } from 'store/vertical-adapters/hooks';
import { useMemo } from 'react';

export const useIntegrationTypeOptions = (verticalUuid: string): SelectItem[] => {
  const [verticalAdapters] = useVerticalAdapters(verticalUuid);

  return useMemo(
    () =>
      verticalAdapters?.map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) ?? [],
    [verticalAdapters],
  );
};
