import React from 'react';
import { useNotifications } from 'store/notifications/hooks';
import { Notification } from 'components/notifications/notification/notification';
import { ToastContainer } from '@hqo/react-components-library';
import styled from 'styled-components';
import { getZIndex } from 'shared/z-index';

const NotificationsWrapper = styled(ToastContainer)`
  z-index: ${getZIndex('notification')};
`;

export const Notifications = (): JSX.Element | null => {
  const notifications = useNotifications();

  if (!notifications.length) {
    return null;
  }

  return (
    <NotificationsWrapper>
      {notifications.map(
        ({ uuid, translationKey, text, autoHideMs, type, dataCy }): JSX.Element => (
          <Notification
            key={uuid}
            uuid={uuid}
            translationKey={translationKey}
            text={text}
            autoHideMs={autoHideMs}
            type={type}
            dataCy={dataCy}
          />
        ),
      )}
    </NotificationsWrapper>
  );
};
