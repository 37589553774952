import { NotificationsState } from 'store/notifications/types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { v4 } from 'uuid';

export const initialState: NotificationsState = [];

export default createReducer(initialState)
  .handleAction(actions.showNotification, (state, action) => [
    ...state,
    {
      uuid: v4(),
      ...action.payload,
    },
  ])
  .handleAction(actions.hideNotification, (state, action) => state.filter(({ uuid }) => uuid !== action.payload));
