import { RootState } from '../reducer';
import { InstanceState, Instance, DeleteAppInstanceConfigState } from 'store/instances/types';
import { createSelector } from 'reselect';

export const instancesState = (state: RootState): RootState['instances'] => state.instances;

export const createAppInstanceConfigLoading = createSelector(
  instancesState,
  (state: RootState['instances']): boolean => state.createAppInstanceConfigState.loading,
);

type InstancesStateSelector = (state: RootState) => InstanceState | null;
export const getInstanceStateSelector = (uuid: string): InstancesStateSelector =>
  createSelector(instancesState, (state: RootState['instances']): InstanceState | null => state.data[uuid]);

type InstanceUpdatingSelector = (state: RootState) => boolean;
export const getAppInstanceUpdatingSelector = (uuid: string): InstanceUpdatingSelector =>
  createSelector(getInstanceStateSelector(uuid), (state: InstanceState | null): boolean => !!state?.updating);

type AppInstanceSelector = (state: RootState) => Instance | null;
export const getAppInstanceSelector = (instanceUuid: string): AppInstanceSelector =>
  createSelector(
    getInstanceStateSelector(instanceUuid),
    (state: InstanceState | null): Instance | null => state?.data ?? null,
  );

export const deleteAppInstanceConfigStateSelector = createSelector(
  instancesState,
  (state: RootState['instances']): DeleteAppInstanceConfigState => state.deleteAppInstanceConfigState,
);
