import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AppsRedirect } from 'components/router/apps-route/apps-redirect';
import { EditConfigRoute } from './edit-config-route';
import { CreateConfigRoute } from './create-config-route';

export const ConfigRoute = (): JSX.Element => {
  const { path } = useRouteMatch<{ appUuid: string }>();

  return (
    <Switch>
      <Route path={path} component={CreateConfigRoute} exact />
      <Route path={`${path}/:configUuid`} component={EditConfigRoute} exact />
      <Route component={AppsRedirect} />
    </Switch>
  );
};
