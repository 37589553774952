import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { EMPTY, of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as instancesActions from './actions';
import { RootDependencies } from 'store/dependencies';
import { Instance } from './types';
import { push } from 'connected-react-router';

export const getInstances: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(instancesActions.getInstances.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      apiClient(state)
        .getInstances()
        .pipe(
          map(({ response }: AjaxResponse) => instancesActions.getInstances.success(response as Instance[])),
          catchError((error: Error) => of(instancesActions.getInstances.failure(error))),
        ),
    ),
  );

export const createAppInstanceConfig: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(instancesActions.createAppInstanceConfig.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .createAppInstanceConfig(action.payload)
        .pipe(
          map(({ response }: AjaxResponse) => instancesActions.createAppInstanceConfig.success(response as Instance)),
          catchError((error: Error) => of(instancesActions.createAppInstanceConfig.failure(error))),
        ),
    ),
  );

export const redirectToEditPage: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(instancesActions.createAppInstanceConfig.success)),
    map(({ payload: appInstanceConfig }) => push(`/instances/${appInstanceConfig.uuid}`)),
  );

export const getInstance: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(instancesActions.getInstance.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { payload: uuid } = action;

      return apiClient(state)
        .getInstance(uuid)
        .pipe(
          map(({ response }: AjaxResponse) => instancesActions.getInstance.success(response as Instance)),
          catchError((error: Error) => of(instancesActions.getInstance.failure({ uuid, error }))),
        );
    }),
  );

export const deleteInstance: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(instancesActions.deleteAppInstanceConfig.request)),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const instanceUuid = state.instances.deleteAppInstanceConfigState.uuid;

      if (!instanceUuid) {
        return EMPTY;
      }

      return apiClient(state)
        .deleteAppInstanceConfig(instanceUuid)
        .pipe(
          map(() => instancesActions.deleteAppInstanceConfig.success(instanceUuid)),
          catchError((error: Error) => of(instancesActions.deleteAppInstanceConfig.failure(error))),
        );
    }),
  );

export const patchInstance: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(instancesActions.patchInstance.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { uuid, appInstanceConfig } = action.payload;

      return apiClient(state)
        .modifyAppInstanceConfig(uuid, appInstanceConfig)
        .pipe(
          map(({ response }: AjaxResponse) => instancesActions.patchInstance.success(response as Instance)),
          catchError((error: Error) => of(instancesActions.patchInstance.failure({ uuid, error }))),
        );
    }),
  );

export const redirectToInstancesPage: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(instancesActions.deleteAppInstanceConfig.success)),
    map(() => push('/instances')),
  );
