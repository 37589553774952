import { createAsyncAction, createAction } from 'typesafe-actions';
import { AppConfigBlobDefinition } from './types';
import { GetEntityResponseDto, GetEntityErrorDto } from 'shared/types';

type GetAppConfigBlobDefinitionResponseDto = GetEntityResponseDto<AppConfigBlobDefinition>;

export const getAppConfigBlobDefinition = createAsyncAction(
  'app_config_blobs/GET_DEFINITION_REQUEST',
  'app_config_blobs/GET_DEFINITION_RESPONSE',
  'app_config_blobs/GET_DEFINITION_FAILURE',
)<string, GetAppConfigBlobDefinitionResponseDto, GetEntityErrorDto>();

export const selectAppConfigBlobDefinition = createAction('app_config_blobs/SELECT')<string | null>();
