import React from 'react';
import { TableRow, TableCell, ActionsTableCell } from '@hqo/react-components-library';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { getZIndex } from 'shared/z-index';

const HeaderCell = styled(TableCell)`
  &.root {
    font-weight: bold;
    background-color: ${({ theme }): string => theme.colors.universal.white};
    z-index: ${getZIndex('tableHeader')};
  }
`;

const ActionHeaderCell = styled(ActionsTableCell)`
  &.head {
    background-color: ${({ theme }): string => theme.colors.universal.white};
    z-index: ${getZIndex('tableHeader')};
  }
`;

export const InstancesTableHeaderRow = (): JSX.Element => (
  <TableRow>
    <HeaderCell>
      <FormattedMessage id="labels.dateCreated" />
    </HeaderCell>
    <HeaderCell>
      <FormattedMessage id="labels.building" />
    </HeaderCell>
    <HeaderCell>
      <FormattedMessage id="labels.developer" />
    </HeaderCell>
    <HeaderCell>
      <FormattedMessage id="labels.appName" />
    </HeaderCell>
    <HeaderCell>
      <FormattedMessage id="labels.instance" />
    </HeaderCell>
    <HeaderCell>
      <FormattedMessage id="labels.status" />
    </HeaderCell>
    <ActionHeaderCell variant="head" colSpan={2}>
      <FormattedMessage id="labels.action" />
    </ActionHeaderCell>
  </TableRow>
);
