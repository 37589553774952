import React from 'react';

import { ArrowLeftChevron, Button } from '@hqo/react-components-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  color: ${({ theme }): string => theme.colors.greys.adminGreyDark};
`;

interface BackLinkProps {
  to: string;
  label: string;
  dataCy?: string;
}
export const BackLink: React.FC<BackLinkProps> = ({ to, label, dataCy }: BackLinkProps): JSX.Element => (
  <StyledLink to={to}>
    <StyledButton
      Icon={<ArrowLeftChevron size="xs" variant="adminGreyDark" />}
      iconDirection="left"
      size="md"
      text={label}
      variant="link"
      dataCy={dataCy}
    />
  </StyledLink>
);
