import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { InfoRow } from './info-row';

const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface AppInfoProps {
  vertical: string;
}

export const AppInfo: React.FC<AppInfoProps> = ({ vertical }: AppInfoProps): JSX.Element => {
  const intl = useIntl();

  return (
    <InfoSection>
      <InfoRow label={intl.formatMessage({ id: 'labels.vertical' })} value={vertical} />
    </InfoSection>
  );
};
