import React, { useMemo } from 'react';
import { FormInput } from 'components/form-input';
import { FormSection } from 'components/form-section';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { useBlobDefinition } from 'components/app-instance-config/hooks/use-blob-definition.hook';
import { FormRenderer, FormRendererProps } from '@hqo/dynamic-form-ui';
import { BlobsLoader } from 'components/blobs-loaders';

interface AppInstanceConfigurationSectionProps {
  className?: string;
  isEditMode?: boolean;
}

export const AppInstanceConfigurationSection: React.FC<AppInstanceConfigurationSectionProps> = ({
  className,
  isEditMode,
}: AppInstanceConfigurationSectionProps): JSX.Element => {
  const [definition, loading] = useBlobDefinition();
  const showBlobsSection = !!definition && !loading;
  const config = useMemo(
    (): FormRendererProps['config'] => ({
      parentKey: 'config',
      isEditMode,
    }),
    [isEditMode],
  );

  return (
    <FormSection
      className={className}
      sectionTranslationKey="appInstanceConfigurationSection"
      instructionsUrl={definition?.instructions_url}
    >
      <FormInput<AppInstanceConfigFormValues>
        sectionTranslationKey="appInstanceConfigurationSection"
        name="base_url"
        dataCy="base-url"
      />
      <FormInput<AppInstanceConfigFormValues>
        sectionTranslationKey="appInstanceConfigurationSection"
        name="external_id"
        dataCy="external-id"
      />
      {loading && <BlobsLoader />}
      {showBlobsSection && <FormRenderer schema={definition} config={config} />}
    </FormSection>
  );
};
