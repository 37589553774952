export enum Verticals {
  ACS = 'ACCESS_CONTROL_SYSTEM',
  ORDER_AHEAD = 'ORDER_AHEAD',
  RESOURCE_BOOKING = 'RESOURCE_BOOKING',
  SERVICE_BOOKING = 'SERVICE_BOOKING',
  VISITOR_REGISTRATION = 'VISITOR_REGISTRATION',
  SERVICE_REQUEST = 'SERVICE_REQUEST',
  MOBILE_ACCESS_CREDENTIALING = 'MOBILE_ACCESS_CREDENTIALING',
  TICKETING = 'TICKETING',
  EMERGENCY_COMMS = 'EMERGENCY_COMMS',
  SHUTTLE_TRACKING = 'SHUTTLE_TRACKING',
}
