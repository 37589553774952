import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { AppConfig } from './types';
import { push } from 'connected-react-router';

export const createAppConfig: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.createAppConfig.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {
        payload: { appUuid, appConfig },
      } = action;

      return apiClient(state)
        .createAppConfig(appUuid, appConfig)
        .pipe(
          map(({ response }: AjaxResponse) => actions.createAppConfig.success(response as AppConfig)),
          catchError((error: Error) => of(actions.createAppConfig.failure(error))),
        );
    }),
  );

export const redirectToEditPage: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.createAppConfig.success)),
    map(({ payload: appConfig }) => push(`/apps/${appConfig.app_uuid}/configs/${appConfig.uuid}`)),
  );

export const getAppConfig: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAppConfig.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {
        payload: { appUuid, appConfigUuid },
      } = action;

      return apiClient(state)
        .getAppConfig(appUuid, appConfigUuid)
        .pipe(
          map(({ response }: AjaxResponse) => actions.getAppConfig.success(response as AppConfig)),
          catchError((error: Error) => of(actions.getAppConfig.failure({ uuid: appConfigUuid, error }))),
        );
    }),
  );

export const patchAppConfig: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.patchAppConfig.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {
        payload: { appUuid, appConfig, appConfigUuid },
      } = action;

      return apiClient(state)
        .patchAppConfig(appUuid, appConfigUuid, appConfig)
        .pipe(
          map(({ response }: AjaxResponse) => actions.patchAppConfig.success(response as AppConfig)),
          catchError((error: Error) => of(actions.patchAppConfig.failure({ uuid: appConfigUuid, error }))),
        );
    }),
  );
