import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ConfigRoute } from './config-route';
import { AppsRedirect } from 'components/router/apps-route/apps-redirect';

export const AppRoute = (): JSX.Element => {
  const { path } = useRouteMatch<{ appUuid: string }>();

  return (
    <Switch>
      <Route path={`${path}/configs`} component={ConfigRoute} />
      <Route component={AppsRedirect} />
    </Switch>
  );
};
