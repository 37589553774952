import React from 'react';
import { Button, HeaderTitle, PageHeader } from '@hqo/react-components-library';
import { useButtonDisabled, useSaveHandler } from './config-header.hooks';
import { BackLink } from '../back-link';
import styled from 'styled-components';

import { HeaderContent } from '@hqo/react-components-library/dist';
import { getZIndex } from 'shared/z-index';

const PageHeaderStyled = styled(PageHeader)`
  padding: 0 64px;
  background-color: ${({ theme }): string => theme.colors.greys.adminGreyLight};
  z-index: ${getZIndex('header')};
`;

const ContentPlaceholder = styled.div`
  height: 24px;
`;

const ButtonStyled = styled(Button)`
  width: 160px;
  height: 40px;
  font-weight: 400;
`;

interface ConfigHeaderProps {
  backLink: string;
  backLinkLabel: string;
  buttonText: string;
  title: string;
  loading?: boolean;
  headerLeftContent?: React.ReactNode;
  isSaveDisabled?: boolean;
}

export const ConfigHeader: React.FC<ConfigHeaderProps> = ({
  backLink,
  backLinkLabel,
  buttonText,
  loading,
  title,
  headerLeftContent = <ContentPlaceholder />,
  isSaveDisabled,
}: ConfigHeaderProps): JSX.Element => {
  const handleClick = useSaveHandler();
  const isButtonDisabled = useButtonDisabled();

  return (
    <PageHeaderStyled variant="transparent">
      <HeaderContent>
        <BackLink to={backLink} label={backLinkLabel} dataCy="back-link" />
        <HeaderTitle>{title}</HeaderTitle>
        {headerLeftContent}
      </HeaderContent>
      <ButtonStyled
        disabled={isButtonDisabled || isSaveDisabled}
        text={buttonText}
        size="lg"
        variant="primaryHeader"
        loading={loading}
        onClick={handleClick}
        dataCy="submit-button"
      />
    </PageHeaderStyled>
  );
};
