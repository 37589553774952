import { useIntl } from 'react-intl';

interface FieldTranslations {
  label: string;
  placeholder: string;
}
export const useFieldTranslations = (sectionKey: string, fieldName: string): FieldTranslations => {
  const intl = useIntl();
  const translationKey = `${sectionKey}.fields.${fieldName}`;

  return {
    label: intl.formatMessage({ id: `${translationKey}.label` }),
    placeholder: intl.formatMessage({ id: `${translationKey}.placeholder` }),
  };
};
