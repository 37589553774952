import React from 'react';
import { TableRow, TableCell, ActionsTableCell, Pencil, Trash } from '@hqo/react-components-library';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { Instance } from '../../../store/instances/types';
import moment from 'moment';
import { useShowDeleteInstanceConfigDialogHandler } from 'store/instances/hooks';

const EditIcon = styled(Pencil)`
  width: 18px;
  height: 18px;
`;

const DeleteIcon = styled(Trash)`
  cursor: pointer;
`;

interface InstancesTableRowProps {
  instance: Instance;
}

export const InstancesTableRow = ({ instance }: InstancesTableRowProps): JSX.Element => {
  const instanceUuid = instance.uuid;
  const editInstanceUrl = `instances/${instanceUuid}`;
  const handleDelete = useShowDeleteInstanceConfigDialogHandler(instanceUuid);

  return (
    <TableRow data-cy={`instance-row-${instanceUuid}`}>
      <TableCell data-cy="created-at">{moment(instance.created_at).format('MM-DD-YYYY').toString()}</TableCell>
      <TableCell data-cy="building-name">{instance.building?.name ?? '-'}</TableCell>
      <TableCell data-cy="developer-name">{instance.company?.name ?? '-'}</TableCell>
      <TableCell data-cy="app-name">{instance.app?.name ?? '-'}</TableCell>
      <TableCell data-cy="owner-type">
        <FormattedMessage id={`instanceTypes.${instance.owner_type.toLowerCase()}`} />
      </TableCell>
      <TableCell data-cy="status">
        <FormattedMessage id={`instanceStatuses.${instance.status.toLowerCase()}`} />
      </TableCell>
      <ActionsTableCell>
        <Link to={editInstanceUrl}>
          <EditIcon variant="adminGreyDark" data-cy="instance-edit-link" />
        </Link>
      </ActionsTableCell>
      <ActionsTableCell>
        <DeleteIcon variant="adminGreyDark" onClick={handleDelete} data-cy="delete-icon" />
      </ActionsTableCell>
    </TableRow>
  );
};
