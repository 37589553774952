import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { AppConfigFormValues } from 'components/app-config';

export const useAppValidatedCheckbox = (): [
  boolean,
  (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
] => {
  const {
    values: { validated_at },
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<AppConfigFormValues>();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setFieldValue('validated_at', new Date());
      } else {
        setFieldValue('validated_at', null);
      }

      setFieldTouched('validated_at', true);
    },
    [setFieldValue, setFieldTouched],
  );
  return [!!validated_at, handleChange];
};
