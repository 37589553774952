import { DescriptionWrapper, StyledLink } from './description.styles';
import React from 'react';
import { useIntl } from 'react-intl';

interface DescriptionProps {
  sectionTranslationKey: string;
  instructionsUrl?: string | null;
}

export const Description: React.FC<DescriptionProps> = ({
  sectionTranslationKey,
  instructionsUrl,
}: DescriptionProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const hereTranslation = formatMessage({ id: `${sectionTranslationKey}.description.here` });
  const description = formatMessage(
    {
      id: instructionsUrl
        ? `${sectionTranslationKey}.descriptionWithHowToLink`
        : `${sectionTranslationKey}.description`,
    },
    {
      link: (
        <StyledLink href={instructionsUrl ?? ''} target="_blank" rel="noreferrer" data-testid="how-to-link">
          {hereTranslation}
        </StyledLink>
      ),
    },
  );
  return <DescriptionWrapper data-testid="description-text">{description}</DescriptionWrapper>;
};
