import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { Formik, Form, FormikHelpers } from 'formik';

interface ConfigFormProps<T> {
  children: React.ReactNode;
  validationSchema: Yup.ObjectSchema<ObjectShape>;
  initialValues: T;
  onSubmit: (values: T) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const ConfigForm = <T extends object>({
  children,
  initialValues,
  onSubmit,
  validationSchema,
}: ConfigFormProps<T>): JSX.Element => {
  const handleSubmit = useCallback(
    (values: T, actions: FormikHelpers<T>): void => {
      onSubmit(values);
      actions.setSubmitting(false);
    },
    [onSubmit],
  );

  return (
    <Formik<T>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
    >
      {(): JSX.Element => <Form>{children}</Form>}
    </Formik>
  );
};
