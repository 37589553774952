import { EntityState } from 'shared/types';
import { Building } from 'store/buildings/types';
import { AppConfig } from 'store/app-configs/types';

export interface InstancesState {
  loading: boolean;
  error: Error | null;
  data: InstancesHash;
  createAppInstanceConfigState: CreateAppInstanceConfigState;
  deleteAppInstanceConfigState: DeleteAppInstanceConfigState;
}

export interface DeleteAppInstanceConfigState {
  uuid: string | null;
  loading: boolean;
}

export interface CreateAppInstanceConfigState {
  loading: boolean;
  error: Error | null;
}

export interface InstancesHash {
  [uuid: string]: InstanceState;
}

export interface App {
  uuid: string;
  name: string;
}

export interface Company {
  uuid: string;
  name: string;
}

export interface InstanceState extends EntityState<Instance | null> {
  order?: number;
  updating?: boolean;
}

export enum AppStatus {
  NEW = 'NEW',
  CONFIGURED = 'CONFIGURED',
  VALIDATED = 'VALIDATED',
}

export enum InstanceOwnerType {
  PORTFOLIO = 'PORTFOLIO',
  BUILDING = 'BUILDING',
  COMPANY = 'COMPANY',
  BUILDING_COMPANY = 'BUILDING_COMPANY',
}

export interface Instance {
  uuid: string;
  building: Building;
  company: Company;
  app: App;
  owner_type: InstanceOwnerType;
  status: AppStatus;
  created_at: string;
  app_config_uuid: string | null;
  owner_uuid: string | null;
  external_id: string | null;
  base_url?: string;
  enabled?: boolean;
  config: Record<string, unknown> | null;
}

export interface AppInstanceConfig {
  uuid: string;
  external_id?: string | null;
  app_config_uuid: string | null;
  base_url?: string | null;
  owner_type: InstanceOwnerType;
  owner_uuid: string | null;
  vertical_uuid: string;
  vertical_adapter_uuid: string;
  config: Record<string, unknown> | null;
  enabled: boolean;
  app_config: AppConfig;
  created_at: Date;
}

export type CreateApInstanceConfigPayload = Pick<
  AppInstanceConfig,
  'app_config_uuid' | 'base_url' | 'enabled' | 'owner_type' | 'owner_uuid' | 'config' | 'external_id'
>;

export type ModifyAppInstanceConfigPayload = Pick<AppInstanceConfig, 'base_url' | 'enabled' | 'config' | 'external_id'>;
