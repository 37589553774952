import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { Developer } from './types';
import { EntityState } from 'shared/types';

export const developersState = (state: RootState): RootState['developers'] => state.developers;

type DeveloperSearchStateSelector = (state: RootState) => EntityState<Developer[]> | null;
export const getDevelopersSearchStateSelector = (query: string): DeveloperSearchStateSelector =>
  createSelector(
    developersState,
    (state: RootState['developers']): EntityState<Developer[]> | null => state.searchCache[query] ?? null,
  );

type DeveloperSelector = (state: RootState) => Developer | null;
export const getDeveloperSelector = (uuid: string): DeveloperSelector =>
  createSelector(developersState, (state: RootState['developers']): Developer | null => state.developers[uuid] ?? null);
