import * as Yup from 'yup';

import { ObjectShape } from 'yup/lib/object';
import { useValidationSchema as useConfigValidationSchema } from '@hqo/dynamic-form-ui';
import { useMemo } from 'react';
import merge from 'lodash.merge';
import { InstanceOwnerType } from 'store/instances/types';
import { useCurrentAppInstanceConfigBlobDefinition } from 'store/app-instance-config-blob-definitions/hooks';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';

export const useValidationSchema = (): Yup.ObjectSchema<ObjectShape> => {
  const [definition] = useCurrentAppInstanceConfigBlobDefinition();
  const configSchema = useConfigValidationSchema(definition);

  return useMemo(
    (): Yup.ObjectSchema<ObjectShape> =>
      Yup.object().shape({
        app_config_uuid: Yup.string().required(),
        base_url: Yup.string().url().nullable(),
        enabled: Yup.boolean(),
        external_id: Yup.string().nullable(),
        owner_type: Yup.string().oneOf(Object.values(InstanceOwnerType)),
        owner_uuid: Yup.string().required(),
        config: configSchema ?? Yup.object().nullable(),
      }),
    [configSchema],
  );
};

export const newInstanceConfigInitialValues: AppInstanceConfigFormValues = {
  app_config_uuid: '',
  base_url: null,
  enabled: true,
  config: null,
  owner_type: InstanceOwnerType.BUILDING,
  owner_uuid: '',
  external_id: null,
};

export const useInitialValues = (initialValues?: Partial<AppInstanceConfigFormValues>): AppInstanceConfigFormValues =>
  useMemo((): AppInstanceConfigFormValues => merge({}, newInstanceConfigInitialValues, initialValues ?? {}), [
    initialValues,
  ]);
