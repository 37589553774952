import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { InstancesPage } from 'pages/instances-page';
import { InstancesRedirect } from './instances-redirect';
import { CreateInstanceRoute } from './create-instance-route';
import { EditAppInstanceRoute } from './edit-instance-route';

export const InstancesRoute = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={InstancesPage} exact />
      <Route path={`${path}/new`} component={CreateInstanceRoute} />
      <Route path={`${path}/:uuid`} component={EditAppInstanceRoute} />
      <Route component={InstancesRedirect} />
    </Switch>
  );
};
