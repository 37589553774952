import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { AppConfigBlobDefinition } from './types';
import { EntityState } from 'shared/types';

export const appConfigBlobDefinitionsState = (state: RootState): RootState['appConfigBlobDefinitions'] =>
  state.appConfigBlobDefinitions;

type AppConfigBlobDefinitionStateSelector = (state: RootState) => EntityState<AppConfigBlobDefinition | null> | null;

export const getAppConfigBlobDefinitionStateSelector = (adapterUuid: string): AppConfigBlobDefinitionStateSelector =>
  createSelector(
    appConfigBlobDefinitionsState,
    (state: RootState['appConfigBlobDefinitions']): ReturnType<AppConfigBlobDefinitionStateSelector> =>
      state.data[adapterUuid] ?? null,
  );
