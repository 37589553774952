import React from 'react';
import { HeaderAndContentLayout, TwoColumnLayout, Button, Trash } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { AppInstanceConfigForm } from 'components/app-instance-config';
import { ConfigHeader } from 'components/config-header';
import { AppInstanceConfigFormContent } from 'components/app-instance-config/app-instance-config-form-content';
import {
  useButtonLoading,
  useAppInstanceInitialValues,
  useSaveAppInstanceHandler,
} from './edit-app-instance-page.hooks';
import { EditAppInstanceRouteParams } from 'components/router/instances-route/edit-instance-route/edit-instance-route.interface';
import { useCurrentAppInstanceConfigBlobDefinition } from 'store/app-instance-config-blob-definitions/hooks';
import { useShowDeleteInstanceConfigDialogHandler } from 'store/instances/hooks';

const HeaderAndContentLayoutStyled = styled(HeaderAndContentLayout)`
  padding-top: 20px;
`;

const TwoColumnLayoutStyled = styled(TwoColumnLayout)`
  grid-gap: 120px;
  padding: 0 64px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  color: ${({ theme }): string => theme.colors.greys.adminGreyDark};
  align-self: flex-start;
`;

export const EditAppInstancePage = (): JSX.Element => {
  const intl = useIntl();
  const {
    params: { uuid },
  } = useRouteMatch<EditAppInstanceRouteParams>();
  const loading = useButtonLoading(uuid);
  const handleSubmit = useSaveAppInstanceHandler(uuid);
  const initialValues = useAppInstanceInitialValues(uuid);
  const [, blobLoading] = useCurrentAppInstanceConfigBlobDefinition();
  const handleDelete = useShowDeleteInstanceConfigDialogHandler(uuid);

  return (
    <AppInstanceConfigForm onSubmit={handleSubmit} initialValues={initialValues}>
      <HeaderAndContentLayoutStyled>
        <ConfigHeader
          backLink="/instances"
          backLinkLabel={intl.formatMessage({ id: 'labels.instances' })}
          buttonText={intl.formatMessage({ id: 'buttons.configure' })}
          title={intl.formatMessage({ id: 'labels.configureInstance' })}
          loading={loading}
          isSaveDisabled={blobLoading}
          headerLeftContent={
            <StyledButton
              iconDirection="left"
              text={intl.formatMessage({ id: 'buttons.delete' })}
              variant="link"
              size="md"
              onClick={handleDelete}
              Icon={<Trash variant="adminGreyDark" size="xs" />}
            />
          }
        />
        <TwoColumnLayoutStyled>
          <AppInstanceConfigFormContent appInstanceConfigUuid={uuid} />
        </TwoColumnLayoutStyled>
      </HeaderAndContentLayoutStyled>
    </AppInstanceConfigForm>
  );
};
