import { useFormikContext } from 'formik';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { useAppInstanceConfigBlobDefinition } from 'store/app-instance-config-blob-definitions/hooks';

export const useBlobDefinition = (): ReturnType<typeof useAppInstanceConfigBlobDefinition> => {
  const {
    values: { app_config_uuid },
  } = useFormikContext<AppInstanceConfigFormValues>();

  return useAppInstanceConfigBlobDefinition(app_config_uuid ?? '');
};
