import React from 'react';
import { useFormikContext } from 'formik';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { DeveloperSelect } from './developer-select';
import { useDeveloper } from 'store/developers/hooks';
import { AppsSelect } from './app-select';
import { useSearchedApp } from 'store/apps/hooks';
import {
  useSelectState,
  useAppConfigChangeHandler,
  useAppChangeHandler,
  useDeveloperChangeHandler,
} from './app-config-select.hooks';

export const AppConfigSelect = (): JSX.Element => {
  const {
    values: { owner_uuid },
  } = useFormikContext<AppInstanceConfigFormValues>();
  const handleAppConfigChange = useAppConfigChangeHandler();
  const [developerUuid, handleDeveloperStateChange] = useSelectState();
  const [appUuid, handleAppChange, setApp] = useSelectState();
  const handleDeveloperChange = useDeveloperChangeHandler(handleDeveloperStateChange, setApp, handleAppConfigChange);
  const selectedDeveloper = useDeveloper(developerUuid);
  const selectedApp = useSearchedApp(appUuid);
  useAppChangeHandler(selectedApp, handleAppConfigChange);

  return (
    <>
      <DeveloperSelect
        disabled={!owner_uuid}
        selectedDeveloper={selectedDeveloper}
        name="developer"
        onChange={handleDeveloperChange}
      />
      <AppsSelect developerUuid={developerUuid} selectedApp={selectedApp} name="app" onChange={handleAppChange} />
    </>
  );
};
