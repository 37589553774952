import { TotalRowsFooter } from '../total-rows-footer';
import React from 'react';
import { Table, TableBody, TableHead } from '@hqo/react-components-library';
import { Instance } from '../../store/instances/types';
import { InstancesTableHeaderRow } from './instances-table-header-row';
import { InstancesTableRow } from './instances-table-row';

interface InstancesTableProps {
  instances: Instance[];
}

export const InstancesTable = ({ instances }: InstancesTableProps): JSX.Element => (
  <>
    <Table>
      <TableHead>
        <InstancesTableHeaderRow />
      </TableHead>
      <TableBody>
        {instances.map((instance) => {
          if (instance) {
            return <InstancesTableRow key={instance.uuid} instance={instance} />;
          }

          return null;
        })}
      </TableBody>
    </Table>
    <TotalRowsFooter count={instances.length} />
  </>
);
