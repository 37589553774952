import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { CreateAppConfigPage } from 'pages/create-app-config-page';
import { AppsRedirect } from 'components/router/apps-route/apps-redirect';
import { useApp } from 'store/apps/hooks';
import { Loading } from 'components/loading';

export const CreateConfigRoute = (): JSX.Element => {
  const {
    path,
    params: { appUuid },
  } = useRouteMatch<{ appUuid: string }>();
  const [, loading, error] = useApp(appUuid);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AppsRedirect />;
  }

  return (
    <Switch>
      <Route path={path} component={CreateAppConfigPage} exact />
      <Route component={AppsRedirect} />
    </Switch>
  );
};
