import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import React, { useMemo } from 'react';

import { Logo } from '../logo';

import { SixSquares, Tools, StickyNote } from '@hqo/react-components-library';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { hasToken } from 'store/user/selectors';

const LeftNavLogoRow = styled.div`
  height: 64px;
  box-shadow: 0 0 10px 0 ${({ theme }): string => theme.colorsRgba.adminGreyDark_25};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeftNavLogo = styled(Logo)`
  color: ${({ theme }): string => theme.colors.universal.white};
  font-family: ${({ theme }): string => theme.fonts[0]};
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  height: 30px;
  padding: 0 0 0 20px;
  width: auto;
  margin-bottom: 0;
  filter: drop-shadow(0px 4px 4px ${({ theme }): string => theme.colorsRgba.adminGreyDark_25});
`;

const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 42px;
  padding: 0px 0px 0px 17px;
  background-color: ${({ theme }): string => theme.colors.universal.transparent};
  box-shadow: none;
  text-decoration: none;
  font-family: ${({ theme }): string => theme.fonts[0]};
  color: ${({ theme }): string => theme.colors.greys.adminGreyMedium};
  .nav-label {
    margin-left: 8px;
  }

  &:not(.active):hover {
    color: ${({ theme }): string => theme.colors.greys.adminGreyMedium};
    background-color: ${({ theme }): string => theme.colors.primaryAlt};
    border-radius: 0;
  }

  &.active {
    font-weight: bold;
  }

  &.active .active-check-mark {
    background: ${({ theme }): string => theme.colors.primaryAlt};
  }
`;

const ActiveTab = styled.div`
  width: 4px;
  height: 22px;
  position: absolute;
  left: 0px;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
`;

const SectionTitle = styled.div`
  font-weight: bold;
  font-family: ${({ theme }): string => theme.fonts[0]};
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }): string => theme.colors.greys.adminGreyMediumAlt};
  margin: 48px 0px 16px 18px;
  cursor: default;
`;

const StyledNav = styled.nav`
  height: 100vh;
  float: left;
  position: sticky;
  top: 0;
  width: 232px;
  flex-shrink: 0;
  background-color: ${({ theme }): string => theme.colors.greys.adminBlack};
`;

const links = [
  {
    to: '/apps',
    labelKey: 'labels.applications',
    icon: <SixSquares variant="white" size="sm" />,
    dataCy: 'nav-applications',
    authorizedOnly: true,
  },
  {
    to: '/instances',
    labelKey: 'labels.instances',
    icon: <Tools variant="white" size="sm" />,
    dataCy: 'nav-instances',
    authorizedOnly: true,
  },
  {
    to: '/docs',
    labelKey: 'labels.docs',
    icon: <StickyNote variant="white" size="sm" />,
    dataCy: 'nav-docs',
    authorizedOnly: false,
  },
];

export const Nav = (): JSX.Element => {
  const isAuthorized = useSelector(hasToken);
  const availableLinks = useMemo(() => (isAuthorized ? links : links.filter(({ authorizedOnly }) => !authorizedOnly)), [
    isAuthorized,
  ]);

  return (
    <StyledNav>
      <LeftNavLogoRow>
        <LeftNavLogo name="whiteLogo" />
      </LeftNavLogoRow>
      <SectionTitle>
        <FormattedMessage id="labels.configuration" />
      </SectionTitle>

      {availableLinks.map(({ to, labelKey, icon, dataCy }) => (
        <NavItem key={dataCy} to={to} activeClassName="active" data-cy={dataCy}>
          <ActiveTab className="active-check-mark" />
          {icon}
          <span className="nav-label">
            <FormattedMessage id={labelKey} />
          </span>
        </NavItem>
      ))}
    </StyledNav>
  );
};
