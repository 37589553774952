import { AppConfigBlobDefinitionsState } from './types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { combineReducers } from 'redux';

export const initialState: AppConfigBlobDefinitionsState = {
  data: {},
  currentDefinitionKey: null,
};

export const data = createReducer(initialState.data)
  .handleAction(actions.getAppConfigBlobDefinition.request, (state, action) => ({
    ...state,
    [action.payload]: { loading: true, data: null, error: null },
  }))
  .handleAction(actions.getAppConfigBlobDefinition.success, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: action.payload.data, error: null },
  }))
  .handleAction(actions.getAppConfigBlobDefinition.failure, (state, action) => ({
    ...state,
    [action.payload.uuid]: { loading: false, data: null, error: action.payload.error },
  }));

export const currentDefinitionKey = createReducer(initialState.currentDefinitionKey).handleAction(
  actions.selectAppConfigBlobDefinition,
  (_state, action) => action.payload,
);

export default combineReducers({ data, currentDefinitionKey });
