import { AppConfig } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { getAppConfigStateSelector } from './selectors';
import { getAppConfig } from './actions';
import { useEffect } from 'react';
import { EntityStateArray } from 'shared/types';

export const useAppConfig = (appUuid: string, appConfigUuid: string): EntityStateArray<AppConfig | null> => {
  const appConfigState = useSelector(getAppConfigStateSelector(appConfigUuid));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appConfigState) {
      dispatch(getAppConfig.request({ appUuid, appConfigUuid }));
    }
  }, [appConfigState]);

  if (!appConfigState) {
    return [null, true, false];
  }

  return [appConfigState.data, !!appConfigState.loading, !!appConfigState.error];
};
