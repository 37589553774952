import React from 'react';

interface InfoUrlProps {
  url: string;
  getComponent: <T>(name: string, isContainer?: boolean) => T;
}
export const InfoUrl = ({ url, getComponent }: InfoUrlProps): JSX.Element => {
  const DownloadUrl = getComponent<React.FC<{ url: string }>>('DownloadUrl', true);

  return <DownloadUrl url={url} />;
};
