import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

interface TotalRowsFooterProps {
  count: number | string;
}

const Footer = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  border: 1px solid ${({ theme }): string => theme.colors.greys.adminGreyMedium};
  background-color: ${({ theme }): string => theme.colors.universal.white};
  border-top: 0 none;
  padding: 0 15px;
  margin: 0;
`;

export const TotalRowsFooter: React.FC<TotalRowsFooterProps> = ({ count }: TotalRowsFooterProps): JSX.Element => (
  <Footer>
    <FormattedMessage id="labels.totalRows" values={{ count }} />
  </Footer>
);
