import { EntityState } from 'shared/types';

export interface AppsState {
  loading: boolean;
  error: Error | null;
  data: AppsHash;
  searchCache: Record<string, Record<string, EntityState<AppSearchDto[]>>>;
  searchedApps: Record<string, AppSearchDto>;
}

export interface AppsHash {
  [uuid: string]: AppState;
}

export interface AppState extends EntityState<App | null> {
  order?: number;
}

export enum AppType {
  STANDARD = 'STANDARD',
  NATIVE = 'NATIVE',
  TEST = 'TEST',
  CUSTOM = 'CUSTOM',
}

export enum AppStatus {
  NEW = 'NEW',
  CONFIGURED = 'CONFIGURED',
  VALIDATED = 'VALIDATED',
}

export interface Vertical {
  uuid: string;
  label: string;
}

export interface Company {
  uuid: string;
  name: string;
  logo_url?: string;
}

export interface App {
  uuid: string;
  name: string;
  type: AppType;
  status: AppStatus;
  vertical: Vertical;
  company: Company;
  config_uuids: string[];
  created_at: string;
}

export type AppSearchDto = Pick<App, 'uuid' | 'name' | 'config_uuids'>;
