import { Epic } from 'redux-observable';
import { filter, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as appsActions from './actions';
import { RootDependencies } from 'store/dependencies';
import { App } from './types';

export const getApp: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$, { apiClient }) =>
  action$.pipe(
    filter(isActionOf(appsActions.getApp.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { payload: appUuid } = action;

      return apiClient(state)
        .getApp(appUuid)
        .pipe(
          map(({ response }: AjaxResponse) => appsActions.getApp.success(response as App)),
          catchError((error: Error) => of(appsActions.getApp.failure({ uuid: appUuid, error }))),
        );
    }),
  );

export const getApps: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$, { apiClient }) =>
  action$.pipe(
    filter(isActionOf(appsActions.getApps.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      apiClient(state)
        .getApps()
        .pipe(
          map(({ response }: AjaxResponse) => appsActions.getApps.success(response as App[])),
          catchError((error: Error) => of(appsActions.getApps.failure(error))),
        ),
    ),
  );

export const searchApps: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$, { apiClient }) =>
  action$.pipe(
    filter(isActionOf(appsActions.searchApps.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {
        payload: { developerUuid, query },
      } = action;

      return apiClient(state)
        .searchApps(developerUuid, query)
        .pipe(
          map(({ response }: AjaxResponse) =>
            appsActions.searchApps.success({
              developerUuid,
              query,
              apps: response as App[],
            }),
          ),
          catchError((error: Error) =>
            of(
              appsActions.searchApps.failure({
                developerUuid,
                query,
                error,
              }),
            ),
          ),
        );
    }),
  );
