import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AppsRoute } from './apps-route';
import { InstancesRoute } from './instances-route';
import { DefaultRedirect } from './default-redirect';
import styled from 'styled-components';
import { Nav } from 'components/nav';
import { PrivateRoute } from 'components/private-route';
import { DocsPage } from 'pages/docs-page';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  & > * {
    font-family: ${({ theme }): string => theme.fonts.join(', ')};
  }
`;

export const Router: React.FC = (): JSX.Element => (
  <>
    <Nav />
    <AppContainer>
      <Switch>
        <PrivateRoute path="/apps" component={AppsRoute} />
        <PrivateRoute path="/instances" component={InstancesRoute} />
        <Route path="/docs" component={DocsPage} />
        <Route component={DefaultRedirect} />
      </Switch>
    </AppContainer>
  </>
);
