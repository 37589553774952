import { useFormikContext } from 'formik';
import { useCallback, MouseEventHandler } from 'react';

export const useSaveHandler = (): MouseEventHandler => {
  const { submitForm } = useFormikContext();

  return useCallback(() => {
    submitForm();
  }, [submitForm]);
};

export const useButtonDisabled = (): boolean => {
  const { dirty, isValid, isValidating } = useFormikContext();

  return !dirty || !isValid || isValidating;
};
