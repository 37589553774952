import React from 'react';
import styled from 'styled-components';

const ServerInput = styled.input`
  &[type='text'].server-input {
    width: 100%;
    max-width: 340px;
    margin: 0;
  }
`;

interface ServerInputContainerProps {
  oas3Actions: {
    setSelectedServer: (server: string) => void;
  };
  specActions: {
    updateJsonSpec: (spec: Record<string, unknown>) => void;
  };
  specSelectors: {
    spec(): {
      toJSON(): Record<string, unknown>;
    };
  };
}
export const ServerInputContainer = ({
  oas3Actions,
  specActions,
  specSelectors,
}: ServerInputContainerProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const url = event.target.value as string;

    specActions.updateJsonSpec({
      ...specSelectors.spec().toJSON(),
      servers: [{ url }],
    });
    oas3Actions.setSelectedServer(url);
  };

  // eslint-disable-next-line react/jsx-no-bind
  return <ServerInput className="server-input" type="text" onChange={handleChange} placeholder="Enter Server URL" />;
};
