import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasToken } from 'store/user/selectors';
import { useCurrentUser } from 'hooks/use-current-user.hook';

interface PrivateRouteProps extends RouteProps {
  redirectTo?: string;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectTo = '/',
  ...routeProps
}: PrivateRouteProps): JSX.Element | null => {
  const [currentUser, loading] = useCurrentUser();
  const isTokenStored = useSelector(hasToken);

  if (currentUser) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...routeProps} />;
  }

  if (!isTokenStored && !loading) {
    return <Redirect to={redirectTo} />;
  }

  return null;
};
