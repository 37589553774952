import React from 'react';
import { SelectTitle, Select, SelectItem, SearchInput } from '@hqo/react-components-library';
import { Building } from 'store/buildings/types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const LoadingLabel = styled.p`
  text-align: center;
`;

const SearchWrapper = styled.div`
  .search-input-container {
    padding: 6px;
  }

  .field.search {
    height: 36px;
  }
`;

interface SelectItem {
  uuid: string;
  name: string;
}

interface DynamicSelectProps {
  label: string;
  placeholder: string;
  selectedItem: SelectItem | null;
  name: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  dataCy: string;
  items: SelectItem[];
  loading: boolean;
  query: string;
  handleSearch: (value: string) => void;
  disabled?: boolean;
}

export const DynamicSelect = ({
  label,
  placeholder,
  selectedItem,
  name,
  onChange: handleChange,
  dataCy,
  items,
  loading,
  query,
  handleSearch,
  disabled,
}: DynamicSelectProps): JSX.Element => (
  <Select
    placeholder={placeholder}
    value={selectedItem?.uuid ?? ''}
    selectedValueLabel={selectedItem?.name ?? ''}
    onChange={handleChange}
    name={name}
    titleComponent={<SelectTitle title={label} />}
    dataCy={dataCy}
    disabled={disabled}
  >
    <SearchWrapper>
      <SearchInput
        value={query}
        setValue={(handleSearch as unknown) as React.Dispatch<React.SetStateAction<string>>}
        dataCy={`${dataCy}-search-input`}
      />
    </SearchWrapper>
    {loading && (
      <LoadingLabel>
        <FormattedMessage id="info.loading" />
      </LoadingLabel>
    )}
    {items.map(
      (item: Building): JSX.Element => (
        <SelectItem
          key={item.uuid}
          value={item.uuid}
          renderedValue={item.name}
          data-cy={`${dataCy}-item-${item.name}`}
        />
      ),
    )}
  </Select>
);
