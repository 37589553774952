import React from 'react';
import styled from 'styled-components';
import { getImageUrl } from 'utils';

const LogoImage = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 4px;
`;

interface CompanyLogoProps {
  className?: string;
  url?: string;
}
export const CompanyLogo: React.FC<CompanyLogoProps> = ({ className, url }: CompanyLogoProps): JSX.Element => (
  <LogoImage className={className} src={getImageUrl(url)} />
);
