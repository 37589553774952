import { createAsyncAction } from 'typesafe-actions';
import { Developer } from './types';

export interface DeveloperSearchResponseDto {
  query: string;
  developers: Developer[];
}

export interface DeveloperSearchErrorDto {
  query: string;
  error: Error;
}

export const searchDevelopers = createAsyncAction(
  'developers/SEARCH_REQUEST',
  'developers/SEARCH_REQUEST_RESPONSE',
  'developers/SEARCH_REQUEST_FAILURE',
)<string, DeveloperSearchResponseDto, DeveloperSearchErrorDto>();
