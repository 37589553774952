import React from 'react';
import { DynamicSelect } from 'components/dynamic-select';
import { useAppsSearch } from 'store/apps/hooks';
import { AppSearchDto } from 'store/apps/types';
import { useFieldTranslations } from 'hooks/form-field';

interface AppSelectProps {
  developerUuid: string | null;
  selectedApp: AppSearchDto | null;
  name: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const AppsSelect = ({
  selectedApp,
  name,
  onChange: handleChange,
  developerUuid,
}: AppSelectProps): JSX.Element => {
  const { label, placeholder } = useFieldTranslations('appInstanceInformationSection', 'app');
  const [apps, loading, query, handleSearch] = useAppsSearch(developerUuid);

  return (
    <DynamicSelect
      disabled={!developerUuid}
      label={label}
      placeholder={placeholder}
      selectedItem={selectedApp}
      name={name}
      dataCy="apps"
      items={apps}
      loading={loading}
      query={query}
      handleSearch={handleSearch}
      onChange={handleChange}
    />
  );
};
