import React from 'react';
import { AppConfigSelect } from './app-config-select';
import { AppConfigViewSection } from './app-config-view-section';

interface AppConfigSelectProps {
  appInstanceConfigUuid?: string;
}

export const AppConfigSection = ({ appInstanceConfigUuid }: AppConfigSelectProps): JSX.Element => {
  if (appInstanceConfigUuid) {
    return <AppConfigViewSection appInstanceConfigUuid={appInstanceConfigUuid} />;
  }

  return <AppConfigSelect />;
};
