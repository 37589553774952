import { VerticalDocsState } from './types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const initialState: VerticalDocsState = {
  data: [],
  error: null,
  loading: false,
  loaded: false,
};

export default createReducer(initialState)
  .handleAction(actions.getVerticalDocs.request, () => ({
    loading: true,
    data: [],
    error: null,
    loaded: false,
  }))
  .handleAction(actions.getVerticalDocs.success, (state, action) => ({
    loading: false,
    data: action.payload,
    error: null,
    loaded: true,
  }))
  .handleAction(actions.getVerticalDocs.failure, (state, action) => ({
    loading: false,
    data: [],
    error: action.payload,
    loaded: true,
  }));
