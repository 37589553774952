import { RootState } from 'store/reducer';
import { ApiClient } from 'store/services/api-client/api-client';

export const apiClient = (
  state: RootState,
  baseUrlOverride?: string | null,
  authTokenOverride?: string | null,
): ApiClient => {
  const baseUrl = baseUrlOverride ?? state.config.apiUrl;
  const authToken = authTokenOverride ?? state.user.token;

  return new ApiClient(baseUrl, authToken);
};
