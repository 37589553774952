import { DevelopersState } from './types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { combineReducers } from 'redux';

export const initialState: DevelopersState = {
  searchCache: {},
  developers: {},
};

const searchCache = createReducer(initialState.searchCache)
  .handleAction(actions.searchDevelopers.request, (state, action) => ({
    ...state,
    [action.payload]: { loading: true, data: [], error: null },
  }))
  .handleAction(actions.searchDevelopers.success, (state, action) => ({
    ...state,
    [action.payload.query]: { loading: false, data: action.payload.developers, error: null },
  }))
  .handleAction(actions.searchDevelopers.failure, (state, action) => ({
    ...state,
    [action.payload.query]: { loading: false, data: [], error: action.payload.error },
  }));

const developers = createReducer(initialState.developers).handleAction(
  actions.searchDevelopers.success,
  (state, action) =>
    action.payload.developers.reduce(
      (developersState, developer) => ({
        ...developersState,
        [developer.uuid]: developer,
      }),
      state,
    ),
);

export default combineReducers({ searchCache, developers });
