import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAppInstanceConfigLoading } from 'store/instances/selectors';
import { AppInstanceConfigFormValues } from 'components/app-instance-config';
import { createAppInstanceConfig } from 'store/instances/actions';

export const useCreateButtonLoading = (): boolean => useSelector(createAppInstanceConfigLoading);

type CreateAppInstanceConfigHandler = (appInstanceConfig: AppInstanceConfigFormValues) => void;
export const useCreateAppInstanceConfigHandler = (): CreateAppInstanceConfigHandler => {
  const dispatch = useDispatch();

  return useCallback(
    (appInstanceConfig: AppInstanceConfigFormValues): void => {
      dispatch(createAppInstanceConfig.request(appInstanceConfig));
    },
    [dispatch],
  );
};
