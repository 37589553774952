import { useEffect } from 'react';
import { FormSchema } from '@hqo/dynamic-form-ui';
import { useFormikContext } from 'formik';
import set from 'lodash.set';
import merge from 'lodash.merge';
import mergeWith from 'lodash.mergewith';

function recursiveMerge(objectA: unknown, objectB: unknown): unknown {
  return mergeWith({}, objectA, objectB, (a: unknown, b: unknown) => {
    if (typeof a === 'object') {
      return recursiveMerge(a, b);
    }

    return a ?? b;
  });
}

export const useConfigDefaultValues = (definition: FormSchema | null, loading: boolean): void => {
  const { values, setValues } = useFormikContext<{ config?: Record<string, unknown> }>();
  useEffect(() => {
    if (!loading && definition) {
      const config = Object.entries(definition.fields ?? {}).reduce(
        (acc, [key, fieldDefinition]) => merge(set({}, key, fieldDefinition.defaultValue), acc),
        {},
      );
      const mergedConfig = recursiveMerge(values?.config ?? {}, config) as Record<string, unknown>;

      setValues({
        ...values,
        config: mergedConfig,
      });
    }
  }, [definition, loading]);
};
