import { Building } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { searchBuildings, getBuilding } from './actions';
import { getBuildingsSearchStateSelector, getBuildingsSelector } from './selectors';

export const useBuildingsSearch = (): [Building[], boolean, string, (value: string) => void] => {
  const [query, setQuery] = useState('');
  const state = useSelector(getBuildingsSearchStateSelector(query));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchBuildings.request(query));
  }, []);

  const handleSearchChange = useCallback(
    (value: string) => {
      const searchQuery = value ?? '';

      setQuery(searchQuery);
      dispatch(searchBuildings.request(searchQuery));
    },
    [setQuery, dispatch, searchBuildings],
  );

  return [state?.data ?? [], state?.loading ?? false, query, handleSearchChange];
};

export const useBuilding = (uuid: string | null): Building | null => {
  const building = useSelector(getBuildingsSelector(uuid ?? ''));
  const dispatch = useDispatch();

  useEffect(() => {
    if (uuid && !building) {
      dispatch(getBuilding.request(uuid));
    }
  }, [uuid, building, dispatch, getBuilding]);

  return building;
};
