import { VerticalDoc } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { verticalsDocsState } from './selectors';
import { getVerticalDocs } from './actions';
import { useEffect } from 'react';
import { EntityStateArray } from 'shared/types';

export const useVerticalDocs = (): EntityStateArray<VerticalDoc[]> => {
  const verticalAdapterState = useSelector(verticalsDocsState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!verticalAdapterState.loaded && !verticalAdapterState.loading) {
      dispatch(getVerticalDocs.request());
    }
  }, [verticalAdapterState]);

  return [verticalAdapterState.data, !!verticalAdapterState.loading, !!verticalAdapterState.error];
};
