import React from 'react';
import { HeaderAndContentLayout, HeaderTitle, PageHeader } from '@hqo/react-components-library';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getZIndex } from 'shared/z-index';
import { useVerticalDocs } from 'store/vertical-docs/hooks';
import { Loading } from 'components/loading';
import { DocsSelect } from 'components/docs-select';
import { useDefaultDoc, useSelectedVerticalDoc } from 'components/docs-select/hooks';
import { SwaggerDocs } from 'components/swagger-docs';

const PageHeaderStyled = styled(PageHeader)`
  z-index: ${getZIndex('header')};
  padding: 89px 68px 47px 64px;
`;

export const DocsPage = (): JSX.Element => {
  const [, loading] = useVerticalDocs();
  useDefaultDoc();
  const selectedDoc = useSelectedVerticalDoc();

  return (
    <HeaderAndContentLayout>
      <PageHeaderStyled>
        <HeaderTitle>
          <FormattedMessage id="labels.docs" />
        </HeaderTitle>
      </PageHeaderStyled>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DocsSelect />
          <SwaggerDocs specUrl={selectedDoc?.docUrl} />
        </>
      )}
    </HeaderAndContentLayout>
  );
};
