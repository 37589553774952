import { createAsyncAction } from 'typesafe-actions';
import { VerticalAdapters } from './types';

interface GetVerticalAdaptersErrorDto {
  error: Error;
  verticalUuid: string;
}

interface GetVerticalAdaptersResponseDto {
  verticalUuid: string;
  adapters: VerticalAdapters;
}

export const getVerticalAdapters = createAsyncAction(
  'vertical/GET_ADAPTERS_REQUEST',
  'vertical/GET_ADAPTERS_RESPONSE',
  'vertical/GET_ADAPTERS_FAILURE',
)<string, GetVerticalAdaptersResponseDto, GetVerticalAdaptersErrorDto>();
