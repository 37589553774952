import { createAsyncAction } from 'typesafe-actions';
import { Building } from './types';

export interface BuildingSearchResponseDto {
  query: string;
  buildings: Building[];
}

export interface BuildingSearchErrorDto {
  query: string;
  error: Error;
}

export const searchBuildings = createAsyncAction(
  'buildings/SEARCH_REQUEST',
  'buildings/SEARCH_REQUEST_RESPONSE',
  'buildings/SEARCH_REQUEST_FAILURE',
)<string, BuildingSearchResponseDto, BuildingSearchErrorDto>();

export const getBuilding = createAsyncAction(
  'buildings/GET_REQUEST',
  'buildings/GET_REQUEST_RESPONSE',
  'buildings/GET_REQUEST_FAILURE',
)<string, Building, Error>();
