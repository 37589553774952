import React from 'react';
import { LabelField } from 'components/label-field';
import { BuildingSelect } from './building-select';
import { useFieldTranslations } from 'hooks/form-field';
import { useFormikContext } from 'formik';
import { AppInstanceConfigFormValues } from 'components/app-instance-config/app-instance-config-form-values.interface';
import { useBuilding } from 'store/buildings/hooks';

interface BuildingFormSelectProps {
  isEditMode?: boolean;
}
export const BuildingFormSelect = ({ isEditMode }: BuildingFormSelectProps): JSX.Element => {
  const {
    values: { owner_uuid },
    handleChange,
  } = useFormikContext<AppInstanceConfigFormValues>();
  const { label, placeholder } = useFieldTranslations('appInstanceInformationSection', 'owner_uuid');
  const selectedBuilding = useBuilding(owner_uuid ?? '');

  if (isEditMode) {
    return <LabelField label={label} value={selectedBuilding?.name ?? ''} />;
  }

  return (
    <BuildingSelect
      label={label}
      placeholder={placeholder}
      selectedBuilding={selectedBuilding}
      name="owner_uuid"
      onChange={handleChange}
    />
  );
};
