import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Header = styled.h1`
  display: flex;
  align-items: center;
  margin: 0;
  height: 64px;
  border: 1px solid ${({ theme }): string => theme.colors.greys.adminGreyMedium};
  background-color: ${({ theme }): string => theme.colors.universal.white};
  border-bottom: 0 none;
  padding: 0 15px;
  font-size: 18px;
`;

export const ConfigureApplicationTableHeader = (): JSX.Element => (
  <Header>
    <FormattedMessage id="labels.configureApplication" />
  </Header>
);
