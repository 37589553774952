import React from 'react';
import { useIntl } from 'react-intl';
import { useApp } from 'store/apps/hooks';
import { LabelField } from 'components/label-field';
import { App } from 'store/apps/types';
import { EntityStateArray } from 'shared/types';
import { FormSection } from 'components/form-section';
import { FormSelect } from 'components/form-select';
import { AppConfigFormValues } from 'components/app-config/app-config-form-values.interface';
import { useIntegrationTypeOptions } from './app-information-section.hooks';

interface AppInformationSectionProps {
  className?: string;
  isEditMode?: boolean;
  appUuid: string;
}
export const AppInformationSection: React.FC<AppInformationSectionProps> = ({
  appUuid,
  className,
  isEditMode,
}: AppInformationSectionProps): JSX.Element => {
  const [app] = useApp(appUuid) as EntityStateArray<App>;
  const adapters = useIntegrationTypeOptions(app.vertical.uuid);
  const intl = useIntl();

  return (
    <FormSection className={className} sectionTranslationKey="appInformationSection">
      <LabelField
        label={intl.formatMessage({ id: 'appInformationSection.labels.developerName' })}
        value={app.company.name}
        dataCy="company-name"
      />
      <LabelField
        label={intl.formatMessage({ id: 'appInformationSection.labels.appName' })}
        value={app.name}
        dataCy="app-name"
      />
      <FormSelect<AppConfigFormValues>
        items={adapters}
        readonly={isEditMode}
        name="vertical_adapter_uuid"
        sectionTranslationKey="appInformationSection"
        dataCy="integration-type"
      />
    </FormSection>
  );
};
